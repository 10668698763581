import { createSlice } from "@reduxjs/toolkit";
import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";

const accountState = {
  userProfile: null,
  appLanguage: null,
  issueCategoryList: null,
  manageResponse: null,
  error: null,
  listLoading: false,
  actionsLoading: false,
  searchLoading: false,
  isRefresh: false,
};

export const accountSlice = createSlice({
  name: "account",
  initialState: accountState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = false;
      }
    },
    throwError: (state, action) => {
      state.error = action.payload.data.error;
    },
    startCall: (state, action) => {
      state.isRefresh = false;
      state.error = null;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = true;
      }
    },
    clearError: (state) => {
      state.error = null;
    },
    userProfileFetched: (state, action) => {
      state.listLoading = false;
      state.userProfile = action.payload.data ?? {};
    },
    userProfileUpdated: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    appLanguageFetched: (state, action) => {
      state.listLoading = false;
      state.appLanguage = action.payload.data ?? {};
    },
    appLanguageUpdated: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    issueCategoryListFetched: (state, action) => {
      state.listLoading = false;
      state.issueCategoryList = action.payload.data ?? {};
    },
    appHelpDeskSubmitted: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    clearAppLanguage: (state, action) => {
      state.appLanguage = null;
    },
    clearUserProfile: (state, action) => {
      state.userProfile = null;
    },
  },
});
