import React from "react";
import { useLang } from "./Metronici18n";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/zh";
import "@formatjs/intl-relativetimeformat/locale-data/th";
import "@formatjs/intl-relativetimeformat/locale-data/vi";

import enMessages from "./messages/en";
import zhMessages from "./messages/zh";
import idMessages from "./messages/id";
import msMessages from "./messages/ms";

export const allMessages = {
  en: enMessages,
  zh: zhMessages,
  id: idMessages,
  ms: msMessages,
};

export function I18nProvider({ children }) {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
