import { useIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";

export const MessageListing = () => {
  const intl = useIntl();

  return (
    <>
      <div className="text-center position-relative p-3 bg-light fw-semibold mb-2">
        {intl.formatMessage({ id: "messages" })}
        <a
          className="cursor-pointer text-decoration-none position-absolute fw-normal"
          style={{ fontSize: "0.8rem", right: "0.85rem" }}
        >
          {intl.formatMessage({ id: "clearAll" })}
        </a>
      </div>
      <div className="bg-light container py-3 mb-2">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="text-muted">13/01/2024</div>
          <a
            className="cursor-pointer text-decoration-none"
            style={{ fontSize: "0.8rem" }}
          >
            {intl.formatMessage({ id: "readAll" })}
          </a>
        </div>
        <div className="d-flex align-items-center  py-2">
          <img
            src={toAbsoluteUrl("images/home/byLaws.png")}
            style={{ width: "30px" }}
            alt="Speaker"
            className="me-3"
          />
          <div className="d-flex flex-column">
            <div>JMB OCTOPUS VILLA BLOCK A</div>
            <div style={{ fontSize: "0.75rem" }} className="text-muted">
              test
            </div>
          </div>
          <div
            style={{ fontSize: "0.8rem" }}
            className="ms-auto text-muted align-self-start pt-1"
          >
            10:31AM
          </div>
        </div>
        <div className="text-muted mt-3">10/01/2024</div>
        <div className="d-flex align-items-center  border-bottom py-2">
          <img
            src={toAbsoluteUrl("images/home/byLaws.png")}
            style={{ width: "30px" }}
            alt="Speaker"
            className="me-3"
          />
          <div className="d-flex flex-column">
            <div>JMB OCTOPUS VILLA BLOCK A</div>
            <div style={{ fontSize: "0.75rem" }} className="text-muted">
              Management Bulletin
            </div>
          </div>
          <div
            style={{ fontSize: "0.8rem" }}
            className="ms-auto text-muted align-self-start pt-1"
          >
            3:01PM
          </div>
        </div>
        <div className="d-flex align-items-center  py-2">
          <img
            src={toAbsoluteUrl("images/home/byLaws.png")}
            style={{ width: "30px" }}
            alt="Speaker"
            className="me-3"
          />
          <div className="d-flex flex-column">
            <div>JMB OCTOPUS VILLA BLOCK A</div>
            <div style={{ fontSize: "0.75rem" }} className="text-muted">
              WATER INTERRUPTION TODAY
            </div>
          </div>
          <div
            style={{ fontSize: "0.8rem" }}
            className="ms-auto text-muted align-self-start pt-1"
          >
            3:01PM
          </div>
        </div>
        <div className="text-muted mt-3">09/01/2024</div>
        <div className="d-flex align-items-center  border-bottom py-2">
          <img
            src={toAbsoluteUrl("images/home/byLaws.png")}
            style={{ width: "30px" }}
            alt="Speaker"
            className="me-3"
          />
          <div className="d-flex flex-column">
            <div>JMB OCTOPUS VILLA BLOCK A</div>
            <div style={{ fontSize: "0.75rem" }} className="text-muted">
              Management Bulletin
            </div>
          </div>
          <div
            style={{ fontSize: "0.8rem" }}
            className="ms-auto text-muted align-self-start pt-1"
          >
            3:01PM
          </div>
        </div>
        <div className="d-flex align-items-center  border-bottom py-2">
          <img
            src={toAbsoluteUrl("images/home/byLaws.png")}
            style={{ width: "30px" }}
            alt="Speaker"
            className="me-3"
          />
          <div className="d-flex flex-column">
            <div>JMB OCTOPUS VILLA BLOCK A</div>
            <div style={{ fontSize: "0.75rem" }} className="text-muted">
              WATER INTERRUPTION TODAY
            </div>
          </div>
          <div
            style={{ fontSize: "0.8rem" }}
            className="ms-auto text-muted align-self-start pt-1"
          >
            3:01PM
          </div>
        </div>
        <div className="d-flex align-items-center  border-bottom py-2">
          <img
            src={toAbsoluteUrl("images/home/byLaws.png")}
            style={{ width: "30px" }}
            alt="Speaker"
            className="me-3"
          />
          <div className="d-flex flex-column">
            <div>JMB OCTOPUS VILLA BLOCK A</div>
            <div style={{ fontSize: "0.75rem" }} className="text-muted">
              WATER INTERRUPTION TODAY
            </div>
          </div>
          <div
            style={{ fontSize: "0.8rem" }}
            className="ms-auto text-muted align-self-start pt-1"
          >
            3:01PM
          </div>
        </div>
        <div className="d-flex align-items-center border-bottom py-2">
          <img
            src={toAbsoluteUrl("images/home/byLaws.png")}
            style={{ width: "30px" }}
            alt="Speaker"
            className="me-3"
          />
          <div className="d-flex flex-column">
            <div>JMB OCTOPUS VILLA BLOCK A</div>
            <div style={{ fontSize: "0.75rem" }} className="text-muted">
              WATER INTERRUPTION TODAY
            </div>
          </div>
          <div
            style={{ fontSize: "0.8rem" }}
            className="ms-auto text-muted align-self-start pt-1"
          >
            3:01PM
          </div>
        </div>
        <div className="d-flex align-items-center  py-2">
          <img
            src={toAbsoluteUrl("images/home/byLaws.png")}
            style={{ width: "30px" }}
            alt="Speaker"
            className="me-3"
          />
          <div className="d-flex flex-column">
            <div>JMB OCTOPUS VILLA BLOCK A</div>
            <div style={{ fontSize: "0.75rem" }} className="text-muted">
              WATER INTERRUPTION TODAY
            </div>
          </div>
          <div
            style={{ fontSize: "0.8rem" }}
            className="ms-auto text-muted align-self-start pt-1"
          >
            3:01PM
          </div>
        </div>
      </div>
    </>
  );
};
