import { useIntl } from "react-intl";
import { shallowEqual } from "react-intl/src/utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as actions from "../../redux/PropertyAction";
import { useEffect, useState } from "react";
import { CheckIsNull, IsAlphaNumeric } from "../../../../utils/CommonHelper";
import SweetAlert2 from "react-sweetalert2";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Input } from "../../../common/control/InputField";
import { OverlayLoading } from "../../../common/OverlayLoading";
import { getUniqueKey } from "../../../auth/redux/LoginAction";

export const PropertyDialog = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [selectedProject, setSelectedProject] = useState();
  const [projectFilter, setProjectFilter] = useState("");
  const [swalProps, setSwalProps] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const propertyState = useSelector(({ property }) => property, shallowEqual);

  useEffect(() => {
    dispatch(actions.getProjectInfo());
  }, []);

  useEffect(() => {
    if (selectedProject) {
      dispatch(
        actions.getCompanyInfo({
          cssuniquekey: getUniqueKey(),
          url: `${selectedProject.serverurl.toLowerCase()}/cssmainapi-core/api/CssProject/GetCompanyInfo`,
          myconnection: {
            servername: selectedProject.server,
            dbname: selectedProject.database,
            dbusername: selectedProject.uid,
            dbpassword: selectedProject.pwd,
            serverurl: selectedProject.serverurl,
          },
        })
      );
    }
    return () => {
      dispatch(actions.clearCompanyInfo());
    };
  }, [selectedProject]);

  const handleAddProperty = (username, password) => {
    setIsLoading(true);

    dispatch(
      actions.addNewProject({
        connection: {
          servername: selectedProject.server,
          dbname: selectedProject.database,
          dbusername: selectedProject.uid,
          dbpassword: selectedProject.pwd,
          serverurl: selectedProject.serverurl,
        },
        username,
        password,
      })
    )
      .then((resp) => {
        setIsLoading(false);
        resp = resp.data;

        if (parseInt(resp.statuscode) === ApiStatusTypeEnum.SUCCESS) {
          setSwalProps({
            show: true,
            icon: "success",
            title: intl.formatMessage({ id: "success" }),
            text: intl.formatMessage({ id: "addPropertySuccessfully" }),
            onResolve: () => {
              setSwalProps({});
              navigate("/property");
            },
          });
        } else {
          setSwalProps({
            show: true,
            icon: "error",
            title: intl.formatMessage({ id: "error" }),
            text: resp.statusdscp,
          });
        }
      })
      .catch((err) => setIsLoading(false));
  };

  const addPropertySchema = () => {
    return Yup.object().shape({
      username: Yup.string()
        .nullable(false)
        .required(intl.formatMessage({ id: "usernameRequired" })),
      password: Yup.string()
        .nullable(false)
        .required(intl.formatMessage({ id: "passwordRequired" })),
    });
  };

  return (
    <div className="h-100 w-100 d-flex flex-column align-items-stretch">
      {isLoading && <OverlayLoading />}
      <SweetAlert2
        heightAuto={false}
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div className="text-center position-relative p-3 bg-light fw-semibold mb-2">
        <a
          className="cursor-pointer text-decoration-none position-absolute fw-normal fs-4"
          style={{ left: "0.85rem", top: "0.8rem" }}
          onClick={!isLoading ? () => navigate("/property") : undefined}
        >
          <i className="bi bi-chevron-left" />
        </a>
        {intl.formatMessage({ id: "addProperty" })}
      </div>
      <div className="flex-fill bg-light scroll-y container py-3">
        {selectedProject ? (
          propertyState && propertyState.companyInfo ? (
            <div className="d-flex flex-column align-items-stretch h-100">
              <div className="d-flex justify-content-between align-items-start mb-4">
                <div className="fw-semibold">{selectedProject.subscriber}</div>
                <a
                  className="cursor-pointer text-decoration-none ms-2"
                  onClick={
                    !isLoading ? () => setSelectedProject(undefined) : undefined
                  }
                >
                  {intl.formatMessage({ id: "change" })}
                </a>
              </div>
              <div className="text-muted mb-4">
                {intl.formatMessage({ id: "addPropertyDescription" })}
              </div>
              <Formik
                initialValues={{
                  username: "",
                  password: "",
                }}
                validationSchema={addPropertySchema()}
                enableReinitialize={true}
                onSubmit={(values) => {
                  handleAddProperty(values.username, values.password);
                }}
              >
                {({ handleSubmit }) => (
                  <Form className="h-100 d-flex flex-column align-items-stretch">
                    <div className="mb-2">
                      <Field
                        name="username"
                        component={Input}
                        placeholder={intl
                          .formatMessage({ id: "loginCode" })
                          .toUpperCase()}
                        label={intl.formatMessage({ id: "username" })}
                        autoComplete="off"
                        type="text"
                        onKeyDown={(e) => {
                          if (!IsAlphaNumeric(e)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className="mb-4">
                      <Field
                        name="password"
                        component={Input}
                        placeholder="******"
                        label={intl.formatMessage({ id: "password" })}
                        autoComplete="off"
                        type="password"
                        onKeyDown={(e) => {
                          if (!IsAlphaNumeric(e)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                    {typeof propertyState.companyInfo === "object" &&
                      propertyState.companyInfo.length > 0 &&
                      propertyState.companyInfo.map((info, index) => (
                        <div className="mb-4" key={index}>
                          {!CheckIsNull(info.companyname) && (
                            <div className="mb-2">{info.companyname}</div>
                          )}
                          {!CheckIsNull(info.companyaddress) && (
                            <div className="mb-2">{info.companyaddress}</div>
                          )}
                          {!CheckIsNull(info.companytelno1) && (
                            <div className="mb-2">{info.companytelno1}</div>
                          )}
                          {!CheckIsNull(info.companytelno2) && (
                            <div className="mb-2">{info.companytelno2}</div>
                          )}
                          {!CheckIsNull(info.companyfax) && (
                            <div className="mb-2">{info.companyfax}</div>
                          )}
                          {!CheckIsNull(info.companyemail) && (
                            <div className="mb-2">{info.companyemail}</div>
                          )}
                          {!CheckIsNull(info.companyremarks) && (
                            <div>{info.companyremarks}</div>
                          )}
                        </div>
                      ))}
                    <div className="flex-fill d-flex flex-column align-items-end justify-content-end pb-2">
                      <button
                        className="btn btn-primary w-100 position-relative"
                        disabled={isLoading}
                        onClick={() => handleSubmit()}
                      >
                        {intl.formatMessage({ id: "addProperty" })}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          ) : (
            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
              <div>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          )
        ) : (
          <>
            <div className="input-group pb-4 border-bottom">
              <input
                type="text"
                value={projectFilter}
                onInput={(e) => setProjectFilter(e.target.value)}
                className="form-control border-end-0 form-control-solid"
                placeholder={intl.formatMessage({ id: "findProperty" })}
              />
              <span className="input-group-text border-start-0 form-control-solid">
                <i
                  className="bi bi-x-circle-fill text-muted"
                  onClick={() => setProjectFilter("")}
                />
              </span>
            </div>
            {propertyState && propertyState.projectInfo ? (
              <>
                {propertyState.projectInfo.filter((info) =>
                  info.subscriber
                    .toLowerCase()
                    .includes(projectFilter.toLowerCase())
                ).length > 0 ? (
                  <>
                    {propertyState.projectInfo
                      .filter((info) =>
                        info.subscriber
                          .toLowerCase()
                          .includes(projectFilter.toLowerCase())
                      )
                      .map((info, index) => (
                        <div
                          className="py-2 border-bottom d-flex justify-content-between align-items-center cursor-pointer"
                          key={index}
                          onClick={() => {
                            setSelectedProject(info);
                            setProjectFilter("");
                          }}
                        >
                          {info.subscriber}
                        </div>
                      ))}
                  </>
                ) : (
                  <div className="p-2 border-bottom text-muted">
                    {intl.formatMessage({ id: "noProject" })}
                  </div>
                )}
              </>
            ) : (
              <div className="d-flex align-items-center justify-content-center mt-4">
                <div>
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
