import { useIntl } from "react-intl";
import * as actions from "../redux/MeetingMinutesAction";
import { useDispatch, useSelector } from "react-redux";
import { shallowEqual } from "react-intl/src/utils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SweetAlert2 from "react-sweetalert2";
import { getUniqueKey } from "../../auth/redux/LoginAction";
import { CheckIsNull } from "../../../utils/CommonHelper";

export const MeetingMinutesListing = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState([]);

  const meetingMinutesState = useSelector(
    ({ meetingMinutes }) => meetingMinutes,
    shallowEqual
  );

  useEffect(() => {
    if (localStorage.getItem("projectData")) {
      const projectData = JSON.parse(localStorage.getItem("projectData"));
      const queryParams = {
        url: projectData.serverurl,
        cssuniquekey: getUniqueKey(),
        cssuserid: projectData.cssuserid,
      };

      dispatch(actions.getMinutesList(queryParams));
      return () => dispatch(actions.clearMinutesList());
    } else {
      navigate("/home");
    }
  }, []);

  return (
    <>
      <div className="h-100 w-100 d-flex flex-column align-items-stretch">
        <SweetAlert2
          heightAuto={false}
          customClass="custom-swal"
          onResolve={() => {
            setSwalProps({});
          }}
          {...swalProps}
        />
        <div className="text-center position-relative p-3 bg-light fw-semibold mb-2">
          <a
            className="cursor-pointer text-decoration-none position-absolute fw-normal fs-4"
            style={{ left: "0.85rem", top: "0.8rem" }}
            onClick={() => navigate("/home")}
          >
            <i className="bi bi-chevron-left" />
          </a>
          {intl.formatMessage({ id: "meetingMinutes" })}
        </div>
        <div
          className={`flex-fill container bg-light scroll-y pt-2 ${
            meetingMinutesState && meetingMinutesState.minutesList
              ? ""
              : "d-flex align-items-center justify-content-center"
          }`}
        >
          {meetingMinutesState &&
          meetingMinutesState.minutesList &&
          meetingMinutesState.minutesList.data ? (
            <>
              {meetingMinutesState.minutesList.data.length > 0 ? (
                meetingMinutesState.minutesList.data.map((minutes, index) => (
                  <div
                    key={index}
                    className="border-bottom d-flex justify-content-between align-items-center cursor-pointer py-2"
                    onClick={() => {
                      if (CheckIsNull(minutes.Filename)) {
                        setSwalProps({
                          show: true,
                          icon: "error",
                          title:
                            meetingMinutesState.minutesList
                              .captionFileNotFoundHeader,
                          text: meetingMinutesState.minutesList
                            .captionFileNotFound,
                        });
                      } else {
                        navigate(
                          `/meetingminutes/${encodeURIComponent(
                            minutes.Filename
                          )}`
                        );
                      }
                    }}
                  >
                    <div>
                      <div className="fw-semibold mb-2">
                        {minutes.headerdescription}
                      </div>
                      <div style={{ fontSize: "0.8rem" }}>
                        {minutes.Datemeet}
                      </div>
                    </div>
                    <i className="bi bi-chevron-right fs-4 " />
                  </div>
                ))
              ) : (
                <div className="text-muted border-bottom pb-3 pt-1 px-2">
                  {intl.formatMessage({ id: "noRecord" })}
                </div>
              )}
            </>
          ) : (
            <div>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
