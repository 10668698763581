/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from "react-router-dom";
import { AccountListing } from "../../modules/account/component/AccountListing";
import { createContext } from "react";
import { AccountSetting } from "../../modules/account/component/AccountSetting";
import { TNCDocReader } from "../../modules/account/component/TNCDocReader";
import { FAQDocReader } from "../../modules/account/component/FAQDocReader";
import { PrivacyPolicyDocReader } from "../../modules/account/component/PrivacyPolicyDocReader";
import { LanguageListing } from "../../modules/account/component/LanguageListing";
import { AppHelpDesk } from "../../modules/account/component/AppHelpDesk";

const AccountContext = createContext();

const AccountWrapper = () => {
  const value = {};

  return (
    <AccountContext.Provider value={value}>
      <Routes>
        <Route path="" element={<AccountListing />} />
        <Route path="setting" element={<AccountSetting />} />
        <Route path="termsandcondition" element={<TNCDocReader />} />
        <Route path="faq" element={<FAQDocReader />} />
        <Route path="privacypolicy" element={<PrivacyPolicyDocReader />} />
        <Route path="language" element={<LanguageListing />} />
        <Route path="apphelpdesk" element={<AppHelpDesk />} />
      </Routes>
    </AccountContext.Provider>
  );
};

export { AccountWrapper };
