import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as actions from "../redux/AccountAction";
import { getUniqueKey } from "../../auth/redux/LoginAction";
import { shallowEqual } from "react-intl/src/utils";
import SweetAlert2 from "react-sweetalert2";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { setLanguage } from "../../../i18n/Metronici18n";
import { OverlayLoading } from "../../common/OverlayLoading";

export const LanguageListing = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState([]);

  const accountState = useSelector(({ account }) => account, shallowEqual);

  useEffect(() => {
    if (localStorage.getItem("projectData")) {
      const projectData = JSON.parse(localStorage.getItem("projectData"));
      const queryParams = {
        url: projectData.serverurl,
        cssuniquekey: getUniqueKey(),
      };

      dispatch(actions.getAppLanguage(queryParams));
      return () => dispatch(actions.clearAppLanguage());
    } else {
      navigate("/account");
    }
  }, []);

  const handleChangeLanguage = (culture) => {
    if (localStorage.getItem("projectData")) {
      const projectData = JSON.parse(localStorage.getItem("projectData"));
      const queryParams = {
        url: projectData.serverurl,
        cssuniquekey: getUniqueKey(),
        culture,
      };

      dispatch(actions.editAppLanguage(queryParams)).then((resp) => {
        resp = resp.data;

        if (parseInt(resp.statuscode) === ApiStatusTypeEnum.SUCCESS) {
          setSwalProps({
            show: true,
            icon: "success",
            title: resp.statusheader,
            text: resp.statusmessage,
            onResolve: () => {
              setSwalProps({});
              setLanguage(culture.split("-")[0]);
            },
          });
        } else {
          setSwalProps({
            show: true,
            icon: "error",
            title: resp.statusheader,
            text: resp.statusmessage,
          });
        }
      });
    }
  };

  return (
    <>
      {accountState.actionsLoading && <OverlayLoading />}
      <div className="h-100 w-100 d-flex flex-column align-items-stretch">
        <SweetAlert2
          heightAuto={false}
          customClass="custom-swal"
          onResolve={() => {
            setSwalProps({});
          }}
          {...swalProps}
        />
        <div className="text-center position-relative p-3 bg-light fw-semibold mb-2">
          <a
            className="cursor-pointer text-decoration-none position-absolute fw-normal fs-4"
            style={{ left: "0.85rem", top: "0.8rem" }}
            onClick={() => navigate("/account")}
          >
            <i className="bi bi-chevron-left" />
          </a>
          {intl.formatMessage({ id: "language" })}
        </div>
        <div
          className={`pt-2 flex-fill bg-light scroll-y container ${
            accountState && accountState.appLanguage
              ? ""
              : "d-flex align-items-center justify-content-center"
          }`}
        >
          {accountState && accountState.appLanguage ? (
            <>
              {accountState.appLanguage.data.map((language) => (
                <div
                  className="py-2 border-bottom d-flex align-items-center justify-content-between"
                  key={language.culture}
                  onClick={() => handleChangeLanguage(language.culture)}
                >
                  <div>{language.language}</div>
                  {language.isActive && (
                    <i
                      className="bi bi-check-circle-fill"
                      style={{ color: "#35a433" }}
                    />
                  )}
                </div>
              ))}
            </>
          ) : (
            <div>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
