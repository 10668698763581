import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import { useState } from "react";
import { DownloadFile } from "../../../utils/CommonHelper";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export const MeetingMinutesDocReader = () => {
  const intl = useIntl();
  const { docUrl } = useParams();
  const navigate = useNavigate();
  const [numPages, setNumPages] = useState(0);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <div className="h-100 w-100 d-flex flex-column align-items-stretch position-relative">
        <div className="text-center position-relative p-3 bg-light fw-semibold mb-2">
          <a
            className="cursor-pointer text-decoration-none position-absolute fw-normal fs-4"
            style={{ left: "0.85rem", top: "0.8rem" }}
            onClick={() => navigate("/meetingminutes")}
          >
            <i className="bi bi-chevron-left" />
          </a>
          {intl.formatMessage({ id: "meetingMinutes" })}
        </div>
        {decodeURIComponent(docUrl).includes(".pdf") ? (
          <Document
            file={decodeURIComponent(docUrl)}
            className="w-100 flex-fill scroll-y"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from({ length: numPages }).map((_, index) => (
              <Page
                pageNumber={index + 1}
                key={index}
                className={numPages === index + 1 ? "mb-4rem" : "mb-2"}
                width={window.innerWidth}
              />
            ))}
          </Document>
        ) : (
          <iframe
            id="docViewer"
            className="w-100 flex-fill"
            frameBorder={0}
            src={decodeURIComponent(docUrl)}
          />
        )}
        {decodeURIComponent(docUrl).includes(".pdf") && (
          <>
            <div className="position-fixed bottom-0 p-2 start-0 end-0 d-flex justify-content-end">
              <div
                className="bg-light rounded shadow p-2 cursor-pointer"
                onClick={() =>
                  DownloadFile(
                    decodeURIComponent(docUrl),
                    decodeURIComponent(docUrl).split("/").at(-1)
                  )
                }
              >
                <i className="bi bi-download fs-4 text-primary" />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
