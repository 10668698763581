import axios from "axios";
import { baseUrl } from "../../common/redux/ApiClient";

export const login = (queryParams) => {
  return axios
    .post(
      `${window.location.protocol}//csshome.intechdecisions.com/api/index.php`,
      {
        url: `${baseUrl}cssmainapi-core/api/CssApp/SendOTPVerifyPhoneNo`,
        data: queryParams,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const verifyOTP = (queryParams) => {
  return axios
    .post(
      `${window.location.protocol}//csshome.intechdecisions.com/api/index.php`,
      {
        url: `${baseUrl}cssmainapi-core/api/CssApp/CheckTACVerify`,
        data: queryParams,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const getUserData = () => {
  const data = localStorage.getItem("UserData");
  if (!data) {
    return;
  }

  try {
    const auth = JSON.parse(data);
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};
