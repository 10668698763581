import { useIntl } from "react-intl";
import { useAuth } from "../../auth/components/AuthInit";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/AccountAction";
import { shallowEqual } from "react-intl/src/utils";
import { useNavigate } from "react-router-dom";

export const AccountListing = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth, logout } = useAuth();

  const accountState = useSelector(({ account }) => account, shallowEqual);

  useEffect(() => {
    dispatch(actions.getUserProfile());

    return () => {
      dispatch(actions.clearUserProfile());
    };
  }, []);

  return (
    <>
      <div className="text-center position-relative p-3 bg-light fw-semibold mb-2">
        {intl.formatMessage({ id: "profile" })}
      </div>
      <div className="fw-bold d-flex align-items-center bg-light container py-2">
        <div
          style={{
            background: "#f06292",
            width: "50px",
            height: "50px",
            color: "#4c2847",
          }}
          className="fs-5 rounded-circle d-flex align-items-center justify-content-center me-3"
        >
          {accountState.userProfile
            ? accountState.userProfile.profileName[0].toUpperCase()
            : "-"}
        </div>
        {accountState.userProfile ? (
          <div>{accountState.userProfile.profileName}</div>
        ) : (
          <p className="placeholder-glow col-6 pt-2">
            <span className="placeholder w-100"></span>
          </p>
        )}
      </div>
      <div className="bg-light container mt-2 mb-4">
        <div
          className="d-flex align-items-center p-2 py-3 border-bottom cursor-pointer"
          onClick={() => navigate("/account/setting")}
        >
          <i className="bi bi-gear me-3" />
          <div>{intl.formatMessage({ id: "profileSettings" })}</div>
          <i className="ms-auto bi bi-chevron-right" />
        </div>
        <div
          className="d-flex align-items-center p-2 py-3 border-bottom"
          onClick={() => {
            sessionStorage.setItem("backTo", "/account");
            navigate("/property");
          }}
        >
          <i className="bi bi-people me-3" />
          <div>{intl.formatMessage({ id: "eCommunityProperty" })}</div>
          <i className="ms-auto bi bi-chevron-right" />
        </div>
        <div
          className="d-flex align-items-center p-2 py-3 border-bottom"
          onClick={() => navigate("/account/termsandcondition")}
        >
          <i className="bi bi-chat-square-text me-3" />
          <div>{intl.formatMessage({ id: "termAndConditions" })}</div>
          <i className="ms-auto bi bi-chevron-right" />
        </div>
        <div
          className="d-flex align-items-center p-2 py-3 border-bottom"
          onClick={() => navigate("/account/privacypolicy")}
        >
          <i className="bi bi-lock me-3" />
          <div>{intl.formatMessage({ id: "privacyPolicy" })}</div>
          <i className="ms-auto bi bi-chevron-right" />
        </div>
        <div
          className="d-flex align-items-center p-2 py-3 border-bottom"
          onClick={() => navigate("/account/faq")}
        >
          <i className="bi bi-gear me-3" />
          <div>{intl.formatMessage({ id: "faq" })}</div>
          <i className="ms-auto bi bi-chevron-right" />
        </div>
        <div
          className="d-flex align-items-center p-2 py-3 border-bottom"
          onClick={() => navigate("/account/language")}
        >
          <i className="bi bi-translate me-3" />
          <div>{intl.formatMessage({ id: "language" })}</div>
          <i className="ms-auto bi bi-chevron-right" />
        </div>
        <div
          className="d-flex align-items-center p-2 py-3"
          onClick={() => navigate("/account/apphelpdesk")}
        >
          <i className="bi bi-question-circle me-3" />
          <div>{intl.formatMessage({ id: "appHelpdesk" })}</div>
          <i className="ms-auto bi bi-chevron-right" />
        </div>
      </div>
      <div className="px-4 container">
        <button
          className="btn btn-primary w-100"
          type="button"
          onClick={() => logout()}
        >
          {intl.formatMessage({ id: "logout" })}
        </button>
      </div>
    </>
  );
};
