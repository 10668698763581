const AUTH_LOCAL_STORAGE_KEY = "UserData";

const getUniqueKey = () => {
  const auth = getAuth();
  if (auth) {
    return auth["uniquekey"];
  }
  return;
};

const getPhoneId = () => {
  const auth = getAuth();
  if (auth) {
    return auth["phoneid"];
  }
  return;
};

const getAuth = () => {
  if (!localStorage) {
    return;
  }

  const lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth = JSON.parse(lsValue);
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAuth = (auth) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

export {
  getAuth,
  setAuth,
  removeAuth,
  getUniqueKey,
  getPhoneId,
  AUTH_LOCAL_STORAGE_KEY,
};
