import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import * as requestToApi from "./StatementCRUD";
import { statementSlice } from "./StatementSlice";

const { actions } = statementSlice;

export const getAccountStatementList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchAccountStatementList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(
            actions.accountStatementListFetched({ data: response.data })
          );
          return response;
        }
      } else {
        throw new Error("Error api: fetchAccountStatementList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const clearAccountStatementList = () => (dispatch) => {
  dispatch(actions.clearAccountStatementList());
};
