export const BottomNavConst = [
  {
    title: "home",
    iconActive: "bi bi-house-door-fill",
    icon: "bi bi-house-door",
    url: "home",
  },
  {
    title: "messages",
    iconActive: "bi bi-chat-right-fill",
    icon: "bi bi-chat-right",
    url: "message",
  },
  {
    title: "account",
    iconActive: "bi bi-person-fill",
    icon: "bi bi-person",
    url: "account",
  },
];
