import {
  authFormPost,
  authPost,
  baseUrl,
  es3Url,
} from "../../common/redux/ApiClient";

export function fetchUserProfile() {
  return authPost(`${baseUrl}cssmainapi-core/api/CssApp/UserProfile`, {});
}

export function updateUserProfile(queryParams) {
  return authPost(
    `${baseUrl}cssmainapi-core/api/CssApp/UpdateUserProfile`,
    queryParams
  );
}

export function fetchAppLanguage(params) {
  return authPost(es3Url, {
    action: "cssposttest",
    url: `${params.url}/cssmainapi-core/api/CssAppCaption/AppLanguage`,
    data: { ...params, url: undefined },
  });
}

export function updateAppLanguage(params) {
  return authPost(es3Url, {
    action: "cssposttest",
    url: `${params.url}/cssmainapi-core/api/CssAppCaption/AppLanguageUpdate`,
    data: { ...params, url: undefined },
  });
}

export function fetchIssueCategoryList() {
  return authPost(
    `${baseUrl}cssmainapi-core/api/AppHelpDesk/IssueCategoryList`,
    {}
  );
}

export function submitAppFeedBack(formData) {
  return authFormPost(
    `${window.location.protocol}//csshome.intechdecisions.com/api/formPost.php`,
    formData
  );
}
