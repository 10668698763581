import { getUniqueKey } from "../../auth/redux/LoginAction";
import {
  authGet,
  authPost,
  baseUrl,
  es3Url,
} from "../../common/redux/ApiClient";

export function fetchUserInfo(params) {
  return authGet(
    `${baseUrl}cssmainapi-core/api/CssEcomUser/GetUserInfo3`,
    params
  );
}

export function fetchProjectInfo(params) {
  return authGet(
    `${baseUrl}cssmainapi-core/api/CssProject/GetProjectList`,
    params
  );
}

export function removeProperty(params) {
  const queryParams = Object.entries({
    ...params,
    cssuniquekey: getUniqueKey(),
  })
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  return authPost(
    `${baseUrl}cssmainapi-core/api/CssEcomUser/DeleteUserProperty2?${queryParams}`,
    params
  );
}

export function fetchCompanyInfo(params) {
  return authPost(es3Url, {
    action: "cssposttest",
    url: params.url,
    data: { ...params, url: undefined },
  });
}

export function createNewProject(params) {
  return authPost(
    `${baseUrl}cssmainapi-core/api/CssEcomUser/AddNewProject_V2`,
    params
  );
}

export function switchDefaultProperty(params) {
  const queryParams = Object.entries({
    ...params,
    cssuniquekey: getUniqueKey(),
  })
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  return authPost(
    `${baseUrl}cssmainapi-core/api/CssEcomUser/UpdateUserDefaultProperty3?${queryParams}`,
    params
  );
}
