import { combineReducers } from "redux";
import { homeSlice } from "../modules/home/redux/HomeSlice";
import { propertySlice } from "../modules/property/redux/PropertySlice";
import { bankInSlipSlice } from "../modules/bankInSlip/redux/BankInSlipSlice";
import { statementSlice } from "../modules/statement/redux/StatementSlice";
import { meetingMinutesSlice } from "../modules/meetingMinutes/redux/MeetingMinutesSlice";
import { accountSlice } from "../modules/account/redux/AccountSlice";

export const rootReducer = combineReducers({
  home: homeSlice.reducer,
  property: propertySlice.reducer,
  bankInSlip: bankInSlipSlice.reducer,
  statement: statementSlice.reducer,
  meetingMinutes: meetingMinutesSlice.reducer,
  account: accountSlice.reducer,
});
