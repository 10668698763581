import { useRef } from "react";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import ReactSelect from "react-select";

export const CountryCodeSelect = ({ phoneCode, setPhoneCode, ...props }) => {
  const dropdownRef = useRef();
  const isMenuOpenRef = useRef(false);

  const Option = ({ innerProps, isDisabled, data, isSelected }) => {
    return !isDisabled ? (
      <div
        {...innerProps}
        className={`cursor-pointer p-1 d-flex align-items-center ${
          isSelected ? "bg-primary text-light" : ""
        }`}
      >
        <div
          className="rounded-circle me-2 country-select__icon"
          style={{
            backgroundImage: `url(https://purecatamphetamine.github.io/country-flag-icons/3x2/${data.value.toUpperCase()}.svg)`,
          }}
        />
        <div>{data.label}</div>
      </div>
    ) : null;
  };

  return (
    <>
      <div
        className="rounded-circle position-absolute country-select__icon cursor-pointer"
        style={{
          zIndex: 9999,
          top: "9.5px",
          right: "5px",
          backgroundImage: `url(https://purecatamphetamine.github.io/country-flag-icons/3x2/${phoneCode.value.toUpperCase()}.svg)`,
        }}
        onClick={(e) => {
          if (isMenuOpenRef.current) {
            dropdownRef.current.blur();
          } else {
            dropdownRef.current.focus();
          }
        }}
      />
      <ReactSelect
        openMenuOnFocus={true}
        ref={dropdownRef}
        options={getCountries().map((country) => ({
          label: `+${getCountryCallingCode(country)}`,
          value: country,
        }))}
        onMenuOpen={() => {
          isMenuOpenRef.current = true;
        }}
        onMenuClose={() => {
          setTimeout(() => {
            isMenuOpenRef.current = false;
          }, 100);
        }}
        classNamePrefix="country-select"
        components={{ Option }}
        value={phoneCode}
        onChange={(value) => {
          setPhoneCode(value);
        }}
        {...props}
      />
    </>
  );
};
