import { useIntl } from "react-intl";
import * as actions from "../redux/StatementAction";
import { useDispatch, useSelector } from "react-redux";
import { shallowEqual } from "react-intl/src/utils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SweetAlert2 from "react-sweetalert2";
import { getUniqueKey } from "../../auth/redux/LoginAction";

export const StatementListing = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState([]);

  const statementState = useSelector(
    ({ statement }) => statement,
    shallowEqual
  );

  useEffect(() => {
    if (localStorage.getItem("projectData")) {
      const projectData = JSON.parse(localStorage.getItem("projectData"));
      const queryParams = {
        url: projectData.serverurl,
        cssuniquekey: getUniqueKey(),
        connectionkey: {
          cssuserid: projectData.cssuserid,
          cssuniquekey: getUniqueKey(),
        },
        appplatform: "ANDROID",
      };

      dispatch(actions.getAccountStatementList(queryParams));
      return () => dispatch(actions.clearAccountStatementList());
    } else {
      navigate("/home");
    }
  }, []);
  return (
    <>
      <div className="h-100 w-100 d-flex flex-column align-items-stretch">
        <SweetAlert2
          heightAuto={false}
          customClass="custom-swal"
          onResolve={() => {
            setSwalProps({});
          }}
          {...swalProps}
        />
        <div className="text-center position-relative p-3 bg-light fw-semibold">
          <a
            className="cursor-pointer text-decoration-none position-absolute fw-normal fs-4"
            style={{ left: "0.85rem", top: "0.8rem" }}
            onClick={() => navigate("/home")}
          >
            <i className="bi bi-chevron-left" />
          </a>
          {intl.formatMessage({ id: "statement" })}
        </div>
        {statementState &&
          statementState.accountStatementList &&
          statementState.accountStatementList.statementcontrol && (
            <div className="mb-2 bg-light container">
              <div className="fw-bold mb-1" style={{ fontSize: "1.1rem" }}>
                {statementState.accountStatementList.statementcontrol.caption1}
              </div>
              <div className="d-flex justify-content-between align-items-center pb-2">
                <div>
                  <div
                    style={{
                      color:
                        statementState.accountStatementList.statementcontrol
                          .caption2textcolorcode,
                    }}
                  >
                    {
                      statementState.accountStatementList.statementcontrol
                        .caption2
                    }
                  </div>
                  <div className="fw-bold fs-5">
                    {statementState.accountStatementList.currentoutstading}
                  </div>
                </div>
                <div
                  className="cursor-pointer rounded ws-nowrap p-2"
                  style={{
                    color:
                      statementState.accountStatementList.statementcontrol
                        .caption5textcolorcode,
                    background: "#5A96F0",
                  }}
                >
                  {
                    statementState.accountStatementList.statementcontrol
                      .caption5
                  }
                </div>
              </div>
            </div>
          )}
        <div
          className={`flex-fill container bg-light scroll-y pt-2 ${
            statementState &&
            statementState.accountStatementList &&
            statementState.accountStatementList.Months
              ? ""
              : "d-flex align-items-center justify-content-center"
          }`}
        >
          {statementState &&
          statementState.accountStatementList &&
          statementState.accountStatementList.Months ? (
            <>
              {statementState.accountStatementList.Months.length > 0 ? (
                statementState.accountStatementList.Months.map(
                  (month, index) => (
                    <div key={index}>
                      <div className="fw-bold mb-2">{month.monthyearname}</div>
                      {month.MonthsDetails.map((details, index) => (
                        <div key={index} className="border-bottom my-2">
                          <div className="d-flex mb-1">
                            <div
                              className="rounded-pill px-2"
                              style={{
                                color: details.billingpaymenttextcolor,
                                background: details.billingpaymentbordercolor,
                                fontSize: "0.8rem",
                              }}
                            >
                              {details.billingpaymenttext}
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="fw-semibold">{details.date}</div>
                            <div
                              className="fw-semibold"
                              style={{
                                color: details.amountcolorcode,
                                fontSize: "0.8rem",
                              }}
                            >
                              {details.amount}
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mb-1">
                            <div
                              style={{
                                color: details.ownertenantcolorcode,
                                fontSize: "0.8rem",
                              }}
                            >
                              {details.ownertenant}
                            </div>
                            <div
                              style={{
                                color: details.viewinvoicetextcolor,
                                fontSize: "0.8rem",
                              }}
                            >
                              {details.viewinvoicetext}
                            </div>
                          </div>
                          <div className="mb-1" style={{ fontSize: "0.8rem" }}>
                            {details.docno}
                          </div>
                          <div
                            className="fw-bold pb-2"
                            style={{
                              color: details.dateduetextcolor,
                              fontSize: "0.8rem",
                            }}
                          >
                            {details.datedue}
                          </div>
                        </div>
                      ))}
                    </div>
                  )
                )
              ) : (
                <div className="text-muted border-bottom pb-3 pt-1 px-2">
                  {intl.formatMessage({ id: "noRecord" })}
                </div>
              )}
            </>
          ) : (
            <div>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
