/* eslint-disable jsx-a11y/anchor-is-valid */
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { LoginDialog } from "../../modules/auth/components/LoginDialog";

const AuthWrapper = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route path="*" element={<Navigate to="/login" />} />
      <Route path="/login" element={<LoginDialog />} />
      <Route index element={<LoginDialog />} />
    </Route>

    <Route element={<Outlet />}></Route>
  </Routes>
);

export { AuthWrapper };
