export const OverlayLoading = () => {
  return (
    <div
      className="position-absolute top-0 end-0 start-0 bottom-0 d-flex align-items-center justify-content-center"
      style={{ background: "rgba(0, 0, 0, 0.3)", zIndex: "9999" }}
    >
      <div className="spinner-border text-light" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
