import { useIntl } from "react-intl";
import {
  CheckIsNull,
  IsKeyPressNumber,
  toAbsoluteUrl,
} from "../../../utils/CommonHelper";
import { useEffect, useRef, useState } from "react";
import { CountryCodeSelect } from "../../common/CountryCodeSelect";
import { useAuth } from "./AuthInit";
import { login, verifyOTP } from "../redux/LoginCRUD";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import OTPInput from "react-otp-input";
import SweetAlert2 from "react-sweetalert2";
import { LoginStepEnum } from "../enum/LoginStepEnum";
import { OverlayLoading } from "../../common/OverlayLoading";

export const LoginDialog = () => {
  const intl = useIntl();
  const [phoneCode, setPhoneCode] = useState({ label: "+60", value: "MY" });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [otpError, setOTPError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loginStep, setLoginStep] = useState(LoginStepEnum.STEP_1);
  const [countDown, setCountDown] = useState(60);
  const [otp, setOtp] = useState("");
  const [swalProps, setSwalProps] = useState({});
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isCountryCodeFocused, setIsCountryCodeFocused] = useState(false);
  const phoneNumberFieldRef = useRef();

  const { saveAuth, setCurrentUser } = useAuth();

  const handleLogin = () => {
    setIsLoading(true);
    setError("");
    if (
      !CheckIsNull(phoneNumber) &&
      phoneNumber[0] !== "0" &&
      phoneNumber.length >= 4 &&
      phoneNumber.length <= 14
    ) {
      const phoneno = (phoneCode.label + phoneNumber).substring(1);
      login({ phoneno })
        .then((resp) => {
          setIsLoading(false);
          if (parseInt(resp.errorcode) === ApiStatusTypeEnum.SUCCESS) {
            setLoginStep(LoginStepEnum.STEP_3);
            setCountDown(60);
          } else {
            if (loginStep === LoginStepEnum.STEP_3) {
              setOTPError(resp.errordscp);
            } else {
              setError(resp.errordscp);
            }
          }
        })

        .catch((error) => handelError());
    } else {
      setError(intl.formatMessage({ id: "invalidNumber" }));
    }
  };

  const handleVerifyOTP = () => {
    setIsLoading(true);
    setOTPError("");
    if (!CheckIsNull(otp) && otp.length === 6) {
      const identifier = (phoneCode.label + phoneNumber).substring(1);
      verifyOTP({ identifier, tac: otp, type: "GMS" })
        .then((resp) => {
          setIsLoading(false);
          if (parseInt(resp.errorcode) === ApiStatusTypeEnum.SUCCESS) {
            saveAuth({ ...resp, phoneid: Date.now() });
            setCurrentUser({ ...resp, phoneid: Date.now() });
          } else {
            setOTPError(resp.errordscp);
          }
        })
        .catch((error) => handelError());
    } else {
      setOTPError(intl.formatMessage({ id: "otpIsRequired" }));
    }
  };

  const handelError = () => {
    setIsLoading(false);
    setSwalProps({
      show: true,
      icon: "error",
      title: intl.formatMessage({ id: "error" }),
      text: intl.formatMessage({ id: "errorOccurPleaseContactAdministrator" }),
    });
  };

  useEffect(() => {
    let interval = null;

    if (loginStep === LoginStepEnum.STEP_3) {
      interval = setInterval(() => {
        if (countDown > 0) {
          setCountDown((prev) => prev - 1);
        } else {
          clearInterval(interval);
        }
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [loginStep, countDown]);

  useEffect(() => {
    if (loginStep === LoginStepEnum.STEP_2 && phoneNumberFieldRef.current) {
      phoneNumberFieldRef.current.focus();
    }
  }, [loginStep]);

  return (
    <div
      className={`w-100 h-100 ${
        loginStep === LoginStepEnum.STEP_1
          ? "d-flex flex-column justify-content-center align-items-center"
          : loginStep === LoginStepEnum.STEP_2
          ? "d-flex flex-column align-items-stretch"
          : "d-flex flex-column align-items-center"
      }`}
      style={
        loginStep === LoginStepEnum.STEP_1
          ? {
              backgroundImage: `url(${toAbsoluteUrl(
                "images/auth/background.png"
              )})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }
          : {}
      }
    >
      {isLoading && <OverlayLoading />}
      <SweetAlert2
        heightAuto={false}
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      {loginStep === LoginStepEnum.STEP_3 ? (
        <>
          <div className="text-center w-100 p-3 bg-light fw-semibold mb-4">
            <a
              className="cursor-pointer text-decoration-none position-absolute fw-normal fs-4"
              style={{ left: "0.85rem", top: "0.8rem" }}
              onClick={() => {
                setOtp("");
                setOTPError("");
                setLoginStep(LoginStepEnum.STEP_2);
              }}
            >
              <i className="bi bi-chevron-left" />
            </a>
            {intl.formatMessage({ id: "verify" })}
          </div>
          <div className="container d-flex flex-column align-items-center">
            <img
              src={toAbsoluteUrl("images/auth/icon.webp")}
              alt=""
              className="w-100 mb-3"
              style={{ maxWidth: "250px" }}
            />
            <div className="mb-2 fs-4 fw-bold ">
              {intl.formatMessage({ id: "verifyYourPhone" })}
            </div>
            <div className="mb-4 text-center">
              {intl.formatMessage(
                { id: "pleaseEnterThe6DigitCodeSentToX" },
                { X: phoneCode.label + phoneNumber }
              )}
            </div>
            <div className="mb-1">
              <OTPInput
                value={otp}
                onChange={(val) => {
                  setOtp(val);
                  setOTPError("");
                }}
                numInputs={6}
                containerStyle="justify-content-center"
                shouldAutoFocus={true}
                renderSeparator={<div className="mx-2 mb-3"></div>}
                renderInput={(props) => (
                  <input
                    {...props}
                    type="number"
                    style={{ width: "2.3em" }}
                    disabled={isLoading}
                    className="form-control otp-field text-center"
                  />
                )}
              />
            </div>
            {!CheckIsNull(otpError) && (
              <div className="text-danger mb-1" style={{ fontSize: "0.8rem" }}>
                {otpError}
              </div>
            )}
            <a
              className="cursor-pointer mb-4"
              onClick={() => {
                if (countDown <= 0) {
                  handleLogin();
                }
              }}
            >
              {intl.formatMessage({ id: "resendCode" })}
            </a>
            <div
              className="d-flex align-items-center justify-content-center text-muted mt-4 mb-1"
              style={{ fontSize: "0.8rem" }}
            >
              <div className="me-1">
                {intl.formatMessage({ id: "resendCode" })}
              </div>
              <div>{intl.formatMessage({ id: "xSec" }, { X: countDown })}</div>
            </div>
            <button
              className="btn btn-primary w-100 position-relative"
              disabled={CheckIsNull(otp) || isLoading || otp.length < 6}
              onClick={handleVerifyOTP}
            >
              {intl.formatMessage({ id: "confirm" })}
            </button>
          </div>
        </>
      ) : loginStep === LoginStepEnum.STEP_2 ? (
        <>
          <div className="text-center w-100 p-3 bg-light fw-semibold mb-2">
            <a
              className="cursor-pointer text-decoration-none position-absolute fw-normal fs-4"
              style={{ left: "0.85rem", top: "0.8rem" }}
              onClick={() => {
                setPhoneNumber("");
                setError("");
                setLoginStep(LoginStepEnum.STEP_1);
              }}
            >
              <i className="bi bi-chevron-left" />
            </a>
            {intl.formatMessage({ id: "login" })}
          </div>
          <div
            className={`container flex-fill d-flex flex-column align-items-stretch ${
              isInputFocused || isCountryCodeFocused
                ? ""
                : "justify-content-between"
            }`}
          >
            <div className="phone-number-field">
              <div className="mb-1" style={{ fontSize: "0.8rem" }}>
                {intl.formatMessage({ id: "phoneNumber" })}
              </div>
              <div className="d-flex">
                <div className="position-relative col-4">
                  <CountryCodeSelect
                    phoneCode={phoneCode}
                    setPhoneCode={setPhoneCode}
                    className={
                      !CheckIsNull(error) ? "coutry-select--is-invalid" : ""
                    }
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => {
                      setTimeout(() => {
                        setIsInputFocused(false);
                      }, 100);
                    }}
                    isDisabled={isLoading}
                    maxMenuHeight={`${window.innerHeight / 3 - 50}px`}
                  />
                </div>
                <input
                  className={`flex-fill form-control ${
                    !CheckIsNull(error) ? "is-invalid" : ""
                  }`}
                  ref={phoneNumberFieldRef}
                  onFocus={() => setIsCountryCodeFocused(true)}
                  onBlur={() => {
                    setTimeout(() => {
                      setIsCountryCodeFocused(false);
                    }, 100);
                  }}
                  style={{ marginLeft: "0.3rem" }}
                  type="number"
                  placeholder={intl.formatMessage({ id: "phoneNumber" })}
                  value={phoneNumber}
                  onInput={(e) => {
                    setPhoneNumber(e.target.value);
                    setError("");
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>
            {!CheckIsNull(error) && (
              <div className="text-danger" style={{ fontSize: "0.8rem" }}>
                {error}
              </div>
            )}
            <div
              className={
                isInputFocused || isCountryCodeFocused
                  ? "flex-fill d-flex align-items-center justify-content-center pb-4 mb-4"
                  : "pb-3"
              }
            >
              <button
                className="btn btn-primary w-100 position-relative"
                disabled={CheckIsNull(phoneNumber) || isLoading}
                onClick={handleLogin}
              >
                {intl.formatMessage({ id: "next" })}
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <img
            src={toAbsoluteUrl("images/auth/icon.webp")}
            alt=""
            className="w-100 mb-3"
            style={{ maxWidth: "250px" }}
          />
          <div className="mb-2 fs-4 fw-bold ">
            {intl.formatMessage({ id: "welcomeToCssHome" })}
          </div>
          <div>{intl.formatMessage({ id: "forEveryOwnerAndTenant" })}</div>
          <div className="mb-4">
            {intl.formatMessage({ id: "manageEverythingInOnePlatform" })}
          </div>
          <div className="container">
            <div className="mb-1" style={{ fontSize: "0.8rem" }}>
              {intl.formatMessage({ id: "phoneNumber" })}
            </div>
            <div className="d-flex position-relative">
              <div
                className="position-absolute start-0 end-0 top-0 bottom-0"
                onClick={() => {
                  setLoginStep(LoginStepEnum.STEP_2);
                }}
                style={{ zIndex: "99999" }}
              />

              <div className="position-relative col-4">
                <CountryCodeSelect
                  phoneCode={phoneCode}
                  setPhoneCode={setPhoneCode}
                  className={
                    !CheckIsNull(error) ? "coutry-select--is-invalid" : ""
                  }
                />
              </div>
              <input
                className={`flex-fill form-control ${
                  !CheckIsNull(error) ? "is-invalid" : ""
                }`}
                ref={phoneNumberFieldRef}
                style={{ marginLeft: "0.3rem" }}
                type="number"
                placeholder={intl.formatMessage({ id: "phoneNumber" })}
                value={phoneNumber}
                onInput={(e) => {
                  setPhoneNumber(e.target.value);
                  setError("");
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
