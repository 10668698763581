/* eslint-disable jsx-a11y/anchor-is-valid */
import { Navigate, Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { ByLawsDocReader } from "../../modules/byLaws/component/ByLawsDocReader";

const ByLawsContext = createContext();

const ByLawsWrapper = () => {
  const value = {};

  return (
    <ByLawsContext.Provider value={value}>
      <Routes>
        <Route path="" element={<Navigate to={"/home"} />} />
        <Route path=":docUrl" element={<ByLawsDocReader />} />
      </Routes>
    </ByLawsContext.Provider>
  );
};

export { ByLawsWrapper };
