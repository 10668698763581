import "./App.css";
import { I18nProvider, MetronicI18nProvider } from "./i18n";
import { AuthProvider } from "./modules/auth/components/AuthInit";
import { Provider } from "react-redux";
import store from "./setup/Store";
import { SkeletonTheme } from "react-loading-skeleton";
import { AppRoutes } from "./routing/AppRoutes";
import "react-slideshow-image/dist/styles.css";

function App() {
  return (
    <Provider store={store}>
      <MetronicI18nProvider>
        <I18nProvider>
          <AuthProvider>
            <SkeletonTheme baseColor="#202020" highlightColor="#444">
              <AppRoutes />
            </SkeletonTheme>
          </AuthProvider>
        </I18nProvider>
      </MetronicI18nProvider>
    </Provider>
  );
}

export default App;
