import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import * as requestToApi from "./AccountCRUD";
import { accountSlice } from "./AccountSlice";

const { actions } = accountSlice;

export const getUserProfile = () => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchUserProfile()
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.userProfileFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchUserProfile");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const editUserProfile = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .updateUserProfile(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.userProfileUpdated({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: updateUserProfile");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

export const getAppLanguage = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchAppLanguage(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.appLanguageFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchAppLanguage");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const editAppLanguage = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .updateAppLanguage(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.appLanguageUpdated({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: updateAppLanguage");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

export const clearUserProfile = () => (dispatch) => {
  dispatch(actions.clearUserProfile());
};

export const clearAppLanguage = () => (dispatch) => {
  dispatch(actions.clearAppLanguage());
};

export const getIssueCategoryList = () => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchIssueCategoryList()
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.issueCategoryListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchIssueCategoryList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const createAppFeedBack = (formData) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .submitAppFeedBack(formData)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.appHelpDeskSubmitted({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: submitAppFeedBack");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
