import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/PropertyAction";
import { getPhoneId } from "../../auth/redux/LoginAction";
import { useNavigate } from "react-router-dom";
import { shallowEqual } from "react-intl/src/utils";
import { Sheet } from "react-modal-sheet";
import SweetAlert2 from "react-sweetalert2";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { OverlayLoading } from "../../common/OverlayLoading";

export const PropertyListing = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedProperty, setSelectedProperty] = useState();
  const [swalProps, setSwalProps] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const propertyState = useSelector(({ property }) => property, shallowEqual);

  useEffect(() => {
    dispatch(actions.getUserInfo3({ phoneid: getPhoneId() }));

    return () => dispatch(actions.clearUserInfo());
  }, []);

  const handleRemoveProperty = () => {
    const property = selectedProperty;
    setSelectedProperty(undefined);

    setSwalProps({
      show: true,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: intl.formatMessage({ id: "confirm" }),
      denyButtonText: intl.formatMessage({ id: "cancel" }),
      icon: "info",
      title: intl.formatMessage({ id: "confirmation" }),
      text: intl.formatMessage({ id: "confirmRemoveProperty" }),
      onResolve: (value) => {
        if (value.isDismissed) {
          setSelectedProperty(property);
        }
        setSwalProps({});
      },
      onConfirm: () => {
        const queryParams = {
          cssuserid: property.cssuserid,
        };
        setIsLoading(true);
        dispatch(actions.deleteProperty(queryParams)).then((resp) => {
          resp = resp.data;

          if (parseInt(resp.statuscode) === ApiStatusTypeEnum.SUCCESS) {
            dispatch(actions.getUserInfo3({ phoneid: getPhoneId() }))
              .then(() => setIsLoading(false))
              .catch((err) => setIsLoading(false));

            setSwalProps({
              show: true,
              icon: "success",
              title: intl.formatMessage({ id: "success" }),
              text: intl.formatMessage({ id: "deletePropertySuccessfully" }),
            });
          } else {
            setIsLoading(false);

            setSwalProps({
              show: true,
              icon: "error",
              title: intl.formatMessage({ id: "error" }),
              text: resp.statusdscp,
              onResolve: () => {
                setSwalProps({});
                setSelectedProperty(property);
              },
            });
          }
        });
      },
    });
  };

  const switchDefaultProperty = () => {
    setIsLoading(true);
    const property = selectedProperty;
    setSelectedProperty(undefined);

    const queryParams = {
      cssuserid: property.cssuserid,
    };

    dispatch(actions.switchDefaultProperty(queryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.statuscode) === ApiStatusTypeEnum.SUCCESS) {
        dispatch(actions.getUserInfo3({ phoneid: getPhoneId() }))
          .then(() => setIsLoading(false))
          .catch((err) => setIsLoading(false));

        setSwalProps({
          show: true,
          icon: "success",
          title: resp.statusheader,
          text: resp.statusmessage,
        });
      } else {
        setIsLoading(false);
        setSwalProps({
          show: true,
          icon: "error",
          title: resp.statusheader,
          text: resp.statusmessage,
          onResolve: () => {
            setSwalProps({});
            setSelectedProperty(property);
          },
        });
      }
    });
  };

  return (
    <div className="h-100 w-100 d-flex flex-column align-items-stretch">
      {isLoading && <OverlayLoading />}
      <SweetAlert2
        heightAuto={false}
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div className="text-center position-relative p-3 bg-light fw-semibold mb-2">
        <a
          className="cursor-pointer text-decoration-none position-absolute fw-normal fs-4"
          style={{ left: "0.85rem", top: "0.8rem" }}
          onClick={() => {
            navigate(
              sessionStorage.getItem("backTo")
                ? sessionStorage.getItem("backTo")
                : "/home"
            );
          }}
        >
          <i className="bi bi-chevron-left" />
        </a>
        {intl.formatMessage({ id: "property" })}
      </div>
      <div
        className={`flex-fill bg-light scroll-y container ${
          propertyState && propertyState.userInfo
            ? ""
            : "d-flex align-items-center justify-content-center"
        }`}
      >
        {propertyState && propertyState.userInfo ? (
          <>
            <div className="mt-3 mb-1">
              {intl.formatMessage({ id: "propertyListing" })}
            </div>
            {propertyState.userInfo.length > 0 ? (
              <>
                {propertyState.userInfo.map((info, index) => (
                  <div
                    className="py-2 border-bottom d-flex justify-content-between align-items-center cursor-pointer"
                    key={index}
                    onClick={() => setSelectedProperty(info)}
                  >
                    <div>
                      <div className="fw-bold mb-1">{info.deptdscp}</div>
                      <div
                        className="text-muted"
                        style={{ fontSize: "0.8rem" }}
                      >
                        {info.deptdscp2}
                      </div>
                    </div>
                    {info.propertydefault === "1" && (
                      <i
                        className="bi bi-check-circle-fill"
                        style={{ color: "#35a433" }}
                      />
                    )}
                  </div>
                ))}
              </>
            ) : (
              <div className="p-2 border-bottom text-muted">
                {intl.formatMessage({ id: "noProperty" })}
              </div>
            )}
          </>
        ) : (
          <div>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
      <div className="bg-light container py-2">
        <button
          type="button"
          className="btn btn-primary w-100"
          onClick={() => navigate("new")}
        >
          {intl.formatMessage({ id: "addProperty" })}
        </button>
      </div>
      <Sheet
        detent="content-height"
        isOpen={selectedProperty ? true : false}
        onClose={() => setSelectedProperty(undefined)}
        disableDrag={true}
      >
        <Sheet.Container>
          {selectedProperty && (
            <Sheet.Content className="container py-4">
              <div className="mb-2 fw-semibold fs-4">
                {intl.formatMessage({ id: "manageProperty" })}
              </div>
              <div className="py-2 mb-4">
                <div className="fw-bold mb-1">{selectedProperty.deptdscp}</div>
                <div className="text-muted" style={{ fontSize: "0.8rem" }}>
                  {selectedProperty.deptdscp2}
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primary w-100 mb-2"
                onClick={switchDefaultProperty}
              >
                {intl.formatMessage({ id: "switchToDefault" })}
              </button>
              <button
                type="button"
                className="btn btn-danger w-100 mb-2"
                onClick={handleRemoveProperty}
              >
                {intl.formatMessage({ id: "removeProperty" })}
              </button>
              <a
                className="cursor-pointer text-decoration-none w-100 text-center"
                onClick={() => setSelectedProperty(undefined)}
              >
                {intl.formatMessage({ id: "cancel" })}
              </a>
            </Sheet.Content>
          )}
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </div>
  );
};
