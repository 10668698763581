/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from "react-router-dom";
import { MessageListing } from "../../modules/message/component/MessageListing";
import { createContext } from "react";

const MessageContext = createContext();

const MessageWrapper = () => {
  const value = {};

  return (
    <MessageContext.Provider value={value}>
      <Routes>
        <Route path="" element={<MessageListing />} />
      </Routes>
    </MessageContext.Provider>
  );
};

export { MessageWrapper };
