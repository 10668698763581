/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from "react-router-dom";
import { StatementListing } from "../../modules/statement/component/StatementListing";
import { createContext } from "react";

const StatementContext = createContext();

const StatementWrapper = () => {
  const value = {};

  return (
    <StatementContext.Provider value={value}>
      <Routes>
        <Route path="" element={<StatementListing />} />
      </Routes>
    </StatementContext.Provider>
  );
};

export { StatementWrapper };
