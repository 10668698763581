import { useIntl } from "react-intl";
import { CheckIsNull, toAbsoluteUrl } from "../../../utils/CommonHelper";
import * as actions from "../redux/HomeAction";
import { useDispatch, useSelector } from "react-redux";
import { shallowEqual } from "react-intl/src/utils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import { getUniqueKey } from "../../auth/redux/LoginAction";
import SweetAlert2 from "react-sweetalert2";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { OverlayLoading } from "../../common/OverlayLoading";

export const HomeListing = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [selectedCondo, setSelectedCondo] = useState();
  const [tilesList, setTilesList] = useState([]);
  const [swalProps, setSwalProps] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const homeState = useSelector(({ home }) => home, shallowEqual);

  useEffect(() => {
    sessionStorage.removeItem("backTo");
    dispatch(actions.getHomePageUI());

    return () => {
      dispatch(actions.clearHomePageUI());
      dispatch(actions.clearCategoryList());
    };
  }, []);

  const uiItem = (type, navigationType) => {
    return homeState &&
      homeState.homePageUI &&
      homeState.homePageUI.find((homePage) =>
        navigationType
          ? homePage.type === type && homePage.navigationType === navigationType
          : homePage.type === type
      )
      ? homeState.homePageUI.find((homePage) =>
          navigationType
            ? homePage.type === type &&
              homePage.navigationType === navigationType
            : homePage.type === type
        )
      : { isActive: false };
  };

  useEffect(() => {
    if (homeState.homePageUI) {
      if (uiItem("custom-condo-dropdown").isActive) {
        const property =
          uiItem("custom-condo-dropdown").items.length > 0
            ? uiItem("custom-condo-dropdown").items.find(
                (item) => item.propertydefault === "1"
              )
            : null;

        setSelectedCondo(property);
        localStorage.setItem("projectData", JSON.stringify(property));
      }

      if (uiItem("Tiles").isActive) {
        dispatch(actions.getCategoryList());
      }
    }
  }, [homeState.homePageUI]);

  const handleByLawClick = () => {
    if (localStorage.getItem("projectData")) {
      setIsLoading(true);

      const projectData = JSON.parse(localStorage.getItem("projectData"));
      const queryParams = {
        url: projectData.serverurl,
        cssuserid: projectData.cssuserid,
        cssuniquekey: getUniqueKey(),
      };

      dispatch(actions.getAdditionalByLaw(queryParams)).then((res) => {
        const data = res.data;
        setIsLoading(false);

        if (parseInt(data.code) === ApiStatusTypeEnum.SUCCESS) {
          if (CheckIsNull(data.Houserulesfile)) {
            setSwalProps({
              show: true,
              icon: "error",
              title: data.captionFileNotFoundHeader,
              text: data.captionFileNotFound,
            });
          } else {
            navigation(`/bylaws/${encodeURIComponent(data.Houserulesfile)}`);
          }
        } else {
          setSwalProps({
            show: true,
            icon: "error",
            title: intl.formatMessage({ id: "error" }),
            text: data.errordscp,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (homeState.categoryList) {
      const [categoryList] = homeState.categoryList;

      if (categoryList.isActive) {
        const merchants = categoryList.merchants.filter(
          (merchant) => merchant.isActive
        );
        const chunkSize = 10;
        let result = [];

        for (let i = 0; i < merchants.length; i += chunkSize) {
          result.push(merchants.slice(i, i + chunkSize));
        }

        setTilesList(result);
      }
    }
  }, [homeState.categoryList]);

  return (
    <>
      <SweetAlert2
        heightAuto={false}
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      {isLoading && <OverlayLoading />}
      {homeState && homeState.homePageUI ? (
        <>
          {uiItem("custom-condo-dropdown").isActive && (
            <div
              className="p-3 container text-muted d-flex align-items-center justify-content-start cursor-pointer"
              onClick={() => navigation("/property")}
            >
              <div className="me-2">
                {selectedCondo
                  ? selectedCondo.propertyunit
                  : intl.formatMessage({ id: "addProperty" })}
              </div>
              <div>
                <i className="bi bi-chevron-down text-primary" />
              </div>
            </div>
          )}
          {uiItem("Carousel", "cssbanner").isActive && (
            <div className="container mb-2">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <div className="fw-bold fs-5">
                  {uiItem("Carousel", "cssbanner").title}
                </div>
                <a className="cursor-pointer text-decoration-none">
                  {intl.formatMessage({ id: "viewAll" })}
                </a>
              </div>
              <div className="scroll-x d-flex pb-2">
                {uiItem("Carousel", "cssbanner").items.map((item, index) => (
                  <div
                    className={`card ${
                      index ? "ms-3" : ""
                    } col-10 cursor-pointer`}
                    key={index}
                    onClick={
                      !CheckIsNull(item.redirectURL)
                        ? () => window.open(item.redirectURL)
                        : undefined
                    }
                  >
                    <img
                      src={item.imageURL}
                      className="card-img-top"
                      alt="..."
                      style={{ height: "8rem" }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {uiItem("Tiles").isActive && (
            <div className="bg-light container pt-3 mb-2">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fw-bold fs-5">{uiItem("Tiles").title}</div>
                <a className="cursor-pointer text-decoration-none">
                  {intl.formatMessage({ id: "viewAll" })}
                </a>
              </div>
              <div>
                {tilesList.length > 0 ? (
                  <Slide
                    autoplay={false}
                    indicators={true}
                    arrows={false}
                    infinite={false}
                    transitionDuration={200}
                  >
                    {tilesList.map((tiles, index) => (
                      <div
                        className="d-flex align-items-center flex-wrap w-100"
                        key={index}
                      >
                        {tiles.map((tile, i) => (
                          <div
                            className="cursor-pointer p-3 d-flex flex-column align-items-center"
                            key={i}
                            style={{ width: "20%" }}
                            onClick={() => {
                              if (tile.name === "By-laws") {
                                handleByLawClick();
                              } else {
                                navigation(
                                  `/${tile.name
                                    .replace(" ", "")
                                    .replace("-", "")
                                    .toLowerCase()}`
                                );
                              }
                            }}
                          >
                            <img
                              src={tile.iconURL}
                              style={{ width: "30px" }}
                              alt={tile.name}
                            />
                            <div
                              className="mt-1 text-center"
                              style={{ fontSize: "0.7rem" }}
                            >
                              {tile.name}
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </Slide>
                ) : (
                  <div className="d-flex align-items-sat justify-content-center p-4">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {uiItem("Carousel", "web").isActive && (
            <div className="container  mb-2">
              <div className="fw-bold fs-5 mb-2">
                {uiItem("Carousel", "web").title}
              </div>
              <div className="scroll-x d-flex pb-2">
                {uiItem("Carousel", "web").items.map((item, index) => (
                  <div
                    className={`card ${
                      index ? "ms-3" : ""
                    } col-10 cursor-pointer`}
                    key={index}
                    onClick={
                      !CheckIsNull(item.redirectURL)
                        ? () => window.open(item.redirectURL)
                        : undefined
                    }
                  >
                    <div
                      style={{ background: "#e3efff" }}
                      className="p-5 d-flex align-items-center justify-content-center rounded"
                    >
                      <div className="fs-1 fw-bold">Banner</div>
                    </div>
                  </div>
                ))}

                <div
                  style={{ background: "#e3efff" }}
                  className="rounded p-5 d-flex align-items-center justify-content-center ms-3"
                >
                  <div className="fs-1 fw-bold">Banner</div>
                </div>
                <div
                  style={{ background: "#e3efff" }}
                  className="rounded p-5 d-flex align-items-center justify-content-center ms-3"
                >
                  <div className="fs-1 fw-bold">Banner</div>
                </div>
              </div>
            </div>
          )}
          {uiItem("CSSNews2").isActive && (
            <div className="bg-light container py-3 my-2">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <div className="fw-bold fs-5">{uiItem("CSSNews2").title}</div>
                <a className="cursor-pointer text-decoration-none">
                  {intl.formatMessage({ id: "viewAll" })}
                </a>
              </div>
              <div className="scroll-x d-flex pb-2">
                {uiItem("CSSNews2").items.map((item, index) => (
                  <div
                    className={`card ${
                      index ? "ms-3" : ""
                    } col-10 cursor-pointer`}
                    key={index}
                    onClick={
                      !CheckIsNull(item.redirectURL)
                        ? () => window.open(item.redirectURL)
                        : undefined
                    }
                  >
                    <img
                      src={item.imageURL}
                      className="card-img-top"
                      alt="..."
                      style={{ height: "8rem" }}
                    />
                    <div className="card-body py-1 px-2">
                      <p className="card-text text-truncate">{item.title}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
};
