import { useEffect, useRef, useState } from "react";
import { shallowEqual } from "react-intl/src/utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../redux/BankInSlipAction";
import { useIntl } from "react-intl";
import { Field, Form, Formik } from "formik";
import { Input } from "../../common/control/InputField";
import { CheckIsNull, ResolveArrayNull } from "../../../utils/CommonHelper";
import { OverlayLoading } from "../../common/OverlayLoading";
import { baseUrl } from "../../common/redux/ApiClient";
import { getUniqueKey } from "../../auth/redux/LoginAction";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";

export const BankInSlipDialog = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState([]);
  const [files, setFiles] = useState(null);
  const [bank, setBank] = useState(undefined);
  const [bankIndex, setBankIndex] = useState(undefined);
  const [isShowBankList, setIsShowBankList] = useState(false);
  const inputFileRef = useRef(null);

  const bankInSlipState = useSelector(
    ({ bankInSlip }) => bankInSlip,
    shallowEqual
  );

  useEffect(() => {
    if (localStorage.getItem("projectData")) {
      const projectData = JSON.parse(localStorage.getItem("projectData"));
      const queryParams = {
        url: projectData.serverurl,
      };

      dispatch(actions.getBankInfoList(queryParams));
      return () => dispatch(actions.clearBankInfoList());
    } else {
      navigate("/home");
    }
  }, []);

  useEffect(() => {
    if (bankInSlipState.bankInfoList) {
      const [initBank] = bankInSlipState.bankInfoList;

      setBank(initBank.bankcode);
    }
  }, [bankInSlipState.bankInfoList]);

  const handleFileChanges = (e) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      setFiles(files ? [...files, selectedFile] : [selectedFile]);
    }
  };

  const handleSubmitBankInSlip = (values) => {
    if (localStorage.getItem("projectData")) {
      const projectData = JSON.parse(localStorage.getItem("projectData"));
      const formData = new FormData();

      if (ResolveArrayNull(files).length > 0) {
        files.forEach((file) => {
          formData.append(`Image[]`, file);
        });
      }

      const queryParams = Object.entries({
        Lotno: encodeURI(values.unitNumber),
        Bankinfo: encodeURI(CheckIsNull(bank) ? values.specifyBank : bank),
        Amount: encodeURI(values.bankInAmount),
        Remarks: encodeURI(values.remark),
        Logincode: encodeURI(projectData.username),
      })
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

      formData.append(
        "Url",
        `${
          projectData.serverurl
        }/cssmainapi-core/api/BankinSlip/SubmitBankInSlip_v4?connectionkey.cssuniquekey=${getUniqueKey()}&connectionkey.cssuserid=${
          projectData.cssuserid
        }&${queryParams}`
      );

      formData.append(
        "formPostLocation",
        encodeURIComponent(`http://35.240.204.237/es3KioskApi/FormData1.ashx`)
      );

      formData.append("action", `csspostformtest`);

      dispatch(actions.createBankInSlip(formData)).then((resp) => {
        resp = resp.data;

        if (parseInt(resp.Code) === ApiStatusTypeEnum.SUCCESS) {
          setSwalProps({
            show: true,
            icon: "success",
            title: resp.MessageHeader,
            text: resp.Text,
            onResolve: () => {
              setSwalProps({});
              navigate("/bankinslip");
            },
          });
        } else {
          setSwalProps({
            show: true,
            icon: "error",
            title: resp.MessageHeader,
            text: resp.Text,
          });
        }
      });
    }
  };

  useEffect(() => {
    const handleClick = () => {
      setIsShowBankList(false);
    };

    window.addEventListener("click", handleClick);

    return () => window.removeEventListener("click", handleClick);
  }, []);

  return (
    <>
      {bankInSlipState.actionsLoading && <OverlayLoading />}
      <div className="h-100 w-100 d-flex flex-column align-items-stretch">
        <SweetAlert2
          heightAuto={false}
          customClass="custom-swal"
          onResolve={() => {
            setSwalProps({});
          }}
          {...swalProps}
        />
        <div className="text-center position-relative p-3 bg-light fw-semibold mb-2">
          <a
            className="cursor-pointer text-decoration-none position-absolute fw-normal fs-4"
            style={{ left: "0.85rem", top: "0.8rem" }}
            onClick={() => navigate("/bankinslip")}
          >
            <i className="bi bi-chevron-left" />
          </a>
          {intl.formatMessage({ id: "bankInSlip" })}
        </div>
        <div
          className={`flex-fill bg-light scroll-y container pt-2 ${
            bankInSlipState && bankInSlipState.bankInfoList
              ? ""
              : "d-flex align-items-center justify-content-center"
          }`}
        >
          {bankInSlipState && bankInSlipState.bankInfoList ? (
            <>
              <Formik
                initialValues={{
                  bankInAmount: "",
                  remark: "",
                  specifyBank: "",
                  unitNumber: localStorage.getItem("projectData")
                    ? JSON.parse(localStorage.getItem("projectData")).lotno
                    : "",
                }}
                enableReinitialize={true}
                onSubmit={(values) => {
                  if (
                    !CheckIsNull(values.bankInAmount) &&
                    !CheckIsNull(values.remark) &&
                    ResolveArrayNull(files).length > 0 &&
                    (CheckIsNull(bank)
                      ? !CheckIsNull(values.specifyBank)
                      : true)
                  )
                    handleSubmitBankInSlip(values);
                }}
              >
                {({ handleSubmit, setFieldValue, values }) => (
                  <Form className="d-flex flex-column align-items-stretch h-100">
                    <div className="mb-2">
                      <label className="mb-2 text-input-label">
                        {intl.formatMessage({ id: "bankName" })}
                      </label>
                      <div
                        className="form-select form-control-solid cursor-pointer position-relative"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsShowBankList(!isShowBankList);
                        }}
                      >
                        {CheckIsNull(bankIndex) ? (
                          <div className="text-muted">
                            {intl.formatMessage({ id: "selectBank" })}
                          </div>
                        ) : (
                          bankInSlipState.bankInfoList[bankIndex].bankname
                        )}

                        <div
                          className={`position-absolute bg-light border rounded scroll-y w-100 start-0 ${
                            isShowBankList ? "" : "d-none"
                          }`}
                          style={{ maxHeight: "400px", top: "40px" }}
                        >
                          {bankInSlipState.bankInfoList.map(
                            (bankInfo, index) => (
                              <div
                                key={index}
                                className="p-2"
                                style={{
                                  color: "#000",
                                  background:
                                    bankIndex === index ? "#E2EFFF" : "",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setBankIndex(index);
                                  setIsShowBankList(false);
                                  setBank(
                                    CheckIsNull(bankInfo.bankcode)
                                      ? bankInfo.bankcode
                                      : bankInfo.bankname
                                  );
                                }}
                              >
                                {bankInfo.bankname}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    {CheckIsNull(bank) && (
                      <div className="mb-2">
                        <Field
                          name="specifyBank"
                          component={Input}
                          placeholder={intl.formatMessage({
                            id: "specifyBank",
                          })}
                          label={intl.formatMessage({ id: "specifyBank" })}
                          autoComplete="off"
                          type="text"
                        />
                      </div>
                    )}
                    <div className="mb-2">
                      <Field
                        name="bankInAmount"
                        component={Input}
                        placeholder={intl.formatMessage({ id: "amount" })}
                        label={intl.formatMessage({ id: "bankInAmount" })}
                        autoComplete="off"
                        type="number"
                      />
                    </div>
                    <div className="mb-2">
                      <Field
                        name="remark"
                        component={Input}
                        placeholder={intl.formatMessage({ id: "remark" })}
                        label={intl.formatMessage({ id: "remark" })}
                        autoComplete="off"
                        type="text"
                      />
                    </div>
                    <div className="mb-4">
                      <Field
                        name="unitNumber"
                        component={Input}
                        placeholder=""
                        label={intl.formatMessage({ id: "unitNumber" })}
                        autoComplete="off"
                        type="text"
                        readOnly={true}
                        muted={false}
                      />
                    </div>
                    <div className="mb-2 flex-fill">
                      <input
                        type="file"
                        className="d-none"
                        ref={inputFileRef}
                        accept="image/*"
                        onChange={(e) => handleFileChanges(e)}
                      />
                      <div className="mb-1 d-flex align-items-center flex-wrap">
                        {files &&
                          files.map((file, index) => (
                            <div
                              style={{ width: "50px", height: "50px" }}
                              key={index}
                              className="rounded me-2 d-flex align-items-center justify-content-center border border-primary overflow-hidden"
                            >
                              <img
                                className="w-100"
                                alt={file.name}
                                src={URL.createObjectURL(file)}
                              />
                            </div>
                          ))}
                        <button
                          className="rounded d-flex justify-content-center align-items-center text-primary border border-primary"
                          type="button"
                          style={{ width: "50px", height: "50px" }}
                          onClick={() => {
                            if (
                              inputFileRef.current &&
                              ResolveArrayNull(files).length < 5
                            ) {
                              inputFileRef.current.value = "";
                              inputFileRef.current.click();
                            }
                          }}
                        >
                          <i className="bi bi-arrow-up fs-3" />
                        </button>
                      </div>
                    </div>
                    <button
                      className="btn btn-primary w-100 position-relative mb-2"
                      disabled={
                        bankInSlipState.actionsLoading ||
                        CheckIsNull(values.bankInAmount) ||
                        CheckIsNull(values.remark) ||
                        ResolveArrayNull(files).length === 0 ||
                        (CheckIsNull(bank)
                          ? CheckIsNull(values.specifyBank)
                          : false)
                      }
                      onClick={() => handleSubmit()}
                    >
                      {intl.formatMessage({ id: "submit" })}
                    </button>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <div>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
