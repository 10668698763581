/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from "react-router-dom";
import { MeetingMinutesListing } from "../../modules/meetingMinutes/component/MeetingMinutesListing";
import { createContext } from "react";
import { MeetingMinutesDocReader } from "../../modules/meetingMinutes/component/MeetingMinutesDocReader";

const MeetingMinutesContext = createContext();

const MeetingMinutesWrapper = () => {
  const value = {};

  return (
    <MeetingMinutesContext.Provider value={value}>
      <Routes>
        <Route path="" element={<MeetingMinutesListing />} />
        <Route path=":docUrl" element={<MeetingMinutesDocReader />} />
      </Routes>
    </MeetingMinutesContext.Provider>
  );
};

export { MeetingMinutesWrapper };
