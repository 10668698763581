import { createSlice } from "@reduxjs/toolkit";
import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";

const propertyState = {
  userInfo: null,
  projectInfo: null,
  companyInfo: null,
  manageResponse: null,
  error: null,
  listLoading: false,
  actionsLoading: false,
  searchLoading: false,
  isRefresh: false,
};

export const propertySlice = createSlice({
  name: "property",
  initialState: propertyState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = false;
      }
    },
    throwError: (state, action) => {
      state.error = action.payload.data.error;
    },
    startCall: (state, action) => {
      state.isRefresh = false;
      state.error = null;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = true;
      }
    },
    clearError: (state) => {
      state.error = null;
    },
    userInfoFetched: (state, action) => {
      state.listLoading = false;
      state.userInfo = action.payload.data ?? {};
    },
    clearUserInfo: (state, action) => {
      state.userInfo = null;
    },
    deleteProperty: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    projectInfoFetched: (state, action) => {
      state.listLoading = false;
      state.projectInfo = action.payload.data ?? {};
    },
    companyInfoFetched: (state, action) => {
      state.listLoading = false;
      state.companyInfo = action.payload.data ?? {};
    },
    clearCompanyInfo: (state, action) => {
      state.companyInfo = null;
    },
    newProjectCreated: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
    defaultPropertySwitched: (state, action) => {
      state.actionsLoading = false;
      state.manageResponse = action.payload.data ?? {};
    },
  },
});
