import { authPost, baseUrl, es3Url } from "../../common/redux/ApiClient";

export function fetchHomePageUI(params) {
  return authPost(`${baseUrl}cssmainapi-core/api/CssApp/Homepage`, params);
}

export function fetchCategoryList() {
  return authPost(`${baseUrl}cssmainapi-core/api/CssApp/Category_V2`, {});
}

export function fetchAdditionalByLaw(params) {
  return authPost(es3Url, {
    action: "cssposttest",
    url: `${params.url}/cssmainapi-core/api/AdditionalByLaw/GetAdditionalbylawList_V2`,
    data: { ...params, url: undefined },
  });
}
