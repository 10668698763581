import { useEffect, useState } from "react";
import { shallowEqual } from "react-intl/src/utils";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../redux/BankInSlipAction";
import { useIntl } from "react-intl";
import { Field, Form, Formik } from "formik";
import { Input } from "../../common/control/InputField";

export const BankInSlipDetail = () => {
  const location = useLocation();
  const { itemid, taskid } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState([]);

  const bankInSlipState = useSelector(
    ({ bankInSlip }) => bankInSlip,
    shallowEqual
  );

  useEffect(() => {
    if (localStorage.getItem("projectData")) {
      const projectData = JSON.parse(localStorage.getItem("projectData"));
      const queryParams = {
        itemid,
        taskid,
        url: projectData.serverurl,
        deptcode: projectData.deptcode,
        myconnection: {
          servername: projectData.servername,
          dbname: projectData.databasename,
          dbusername: projectData.dbusername,
          dbpassword: projectData.dbpassword,
          serverurl: projectData.serverurl,
        },
      };

      dispatch(actions.getBankInSlipDetail(queryParams));
      return () => dispatch(actions.clearBankInSlipDetail());
    } else {
      navigate("/home");
    }
  }, [itemid]);

  return (
    <>
      <div className="h-100 w-100 d-flex flex-column align-items-stretch">
        <SweetAlert2
          heightAuto={false}
          customClass="custom-swal"
          onResolve={() => {
            setSwalProps({});
          }}
          {...swalProps}
        />
        <div className="text-center position-relative p-3 bg-light fw-semibold mb-2">
          <a
            className="cursor-pointer text-decoration-none position-absolute fw-normal fs-4"
            style={{ left: "0.85rem", top: "0.8rem" }}
            onClick={() =>
              navigate("/bankinslip", {
                state: {
                  selectedTab:
                    location.state && location.state.selectedTab
                      ? location.state.selectedTab
                      : undefined,
                },
              })
            }
          >
            <i className="bi bi-chevron-left" />
          </a>
          {intl.formatMessage({ id: "bankInSlip" })}
        </div>
        <div
          className={`flex-fill bg-light scroll-y container pt-2 ${
            bankInSlipState && bankInSlipState.bankInSlipDetail
              ? ""
              : "d-flex align-items-center justify-content-center"
          }`}
        >
          {bankInSlipState && bankInSlipState.bankInSlipDetail ? (
            <>
              <div className="d-flex justify-content-between align-items-center mb-2 pt-2">
                <div>{intl.formatMessage({ id: "status" })}</div>
                <div
                  className="p-2 py-1 rounded fw-semibold"
                  style={{
                    fontSize: "0.8rem",
                    background:
                      bankInSlipState.bankInSlipDetail
                        .statusbackgroundcolorcode,
                    color: bankInSlipState.bankInSlipDetail.statuscolorcode,
                  }}
                >
                  {bankInSlipState.bankInSlipDetail.StatusDscp}
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div>{intl.formatMessage({ id: "refNumber" })}</div>
                <div>{bankInSlipState.bankInSlipDetail.Refno}</div>
              </div>
              <div className="d-flex justify-content-between align-items-center pb-3 mb-3 border-bottom">
                <div>{intl.formatMessage({ id: "dateSubmission" })}</div>
                <div>{bankInSlipState.bankInSlipDetail.Dateentry}</div>
              </div>
              <Formik
                initialValues={{
                  bankName: bankInSlipState.bankInSlipDetail.Bankinfo,
                  bankInAmount: bankInSlipState.bankInSlipDetail.Amount,
                  remark: bankInSlipState.bankInSlipDetail.Remarks,
                  unitNumber: bankInSlipState.bankInSlipDetail.lotno,
                }}
                enableReinitialize={true}
                onSubmit={(values) => {}}
              >
                {({ handleSubmit, setFieldValue, values }) => (
                  <Form className="d-flex flex-column align-items-stretch">
                    <div className="mb-2">
                      <Field
                        name="bankName"
                        component={Input}
                        placeholder=""
                        label={intl.formatMessage({ id: "bankName" })}
                        autoComplete="off"
                        type="text"
                        readOnly={true}
                        muted={false}
                      />
                    </div>
                    <div className="mb-2">
                      <Field
                        name="bankInAmount"
                        component={Input}
                        placeholder=""
                        label={intl.formatMessage({ id: "bankInAmount" })}
                        autoComplete="off"
                        type="text"
                        readOnly={true}
                        muted={false}
                      />
                    </div>
                    <div className="mb-2">
                      <Field
                        name="remark"
                        component={Input}
                        placeholder=""
                        label={intl.formatMessage({ id: "remark" })}
                        autoComplete="off"
                        type="text"
                        readOnly={true}
                        muted={false}
                      />
                    </div>
                    <div className="mb-4">
                      <Field
                        name="unitNumber"
                        component={Input}
                        placeholder=""
                        label={intl.formatMessage({ id: "unitNumber" })}
                        autoComplete="off"
                        type="text"
                        readOnly={true}
                        muted={false}
                      />
                    </div>
                    <div className="mb-1 d-flex align-items-center flex-wrap">
                      {bankInSlipState.bankInSlipDetail.Lists &&
                        bankInSlipState.bankInSlipDetail.Lists.map(
                          (list, index) => (
                            <div
                              key={index}
                              style={{ width: "50px", height: "50px" }}
                              className="rounded me-2 d-flex align-items-center justify-content-center border border-primary overflow-hidden"
                            >
                              <img
                                className="w-100"
                                alt=""
                                src={list.FileName}
                              />
                            </div>
                          )
                        )}
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <div>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
