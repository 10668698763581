import axios from "axios";
import { getAuth, getUniqueKey } from "../../auth/redux/LoginAction";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { appIntl } from "../../../i18n/IntlGlobalProvider";

export const baseUrl = "https://www.cssdecisions.com/";
export const es3Url = "http://35.240.204.237/es3KioskApi/Agent5.ashx";

const checkUserDataExist = () => {
  if (!getAuth()) {
    redirectBackToLogin();
  }
};

const redirectBackToLogin = () => {
  window.location.href = "/login";
};

export const authPost = async (endPoint, data) => {
  checkUserDataExist();

  return axios
    .post(
      `${window.location.protocol}//csshome.intechdecisions.com/api/index.php`,
      {
        url: endPoint,
        data: {
          ...data,
          cssuniquekey: getUniqueKey(),
        },
      }
    )
    .then((resp) => {
      if (parseInt(resp.data.code) === ApiStatusTypeEnum.SESSION_LOST) {
        alert(appIntl().formatMessage({ id: "sessionLost" }));
        localStorage.clear();
        window.location.href = window.location.origin;
      } else {
        return resp;
      }
    })
    .catch((error) => handleError(error));
};

export const authFormPost = async (endPoint, data) => {
  checkUserDataExist();

  return axios
    .postForm(endPoint, data)
    .then((resp) => {
      if (parseInt(resp.data.code) === ApiStatusTypeEnum.SESSION_LOST) {
        alert(appIntl().formatMessage({ id: "sessionLost" }));
        localStorage.clear();
        window.location.href = window.location.origin;
      } else {
        return resp;
      }
    })
    .catch((error) => handleError(error));
};

export const authGet = async (endPoint, data) => {
  checkUserDataExist();
  const params = Object.entries({
    ...data,
    cssuniquekey: getUniqueKey(),
  })
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  return axios
    .post(
      `${window.location.protocol}//csshome.intechdecisions.com/api/getApi.php`,
      {
        url: `${endPoint}?${params}`,
      }
    )
    .then((resp) => {
      if (parseInt(resp.data.code) === ApiStatusTypeEnum.SESSION_LOST) {
        alert(appIntl().formatMessage({ id: "sessionLost" }));
        localStorage.clear();
        window.location.href = window.location.origin;
      } else {
        return resp;
      }
    })
    .catch((error) => handleError(error));
};

export const doPost = async (endPoint, data) => {
  return axios
    .post(
      `${window.location.protocol}//csshome.intechdecisions.com/api/index.php`,
      {
        url: endPoint,
        data: data,
      }
    )
    .catch((error) => handleError(error));
};

export const handleError = (error) => {
  if (!(error.message && error.message === "canceled")) {
    alert(appIntl().formatMessage({ id: "errorOccurContactAdmin" }));
  }
};
