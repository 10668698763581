import { createSlice } from "@reduxjs/toolkit";
import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";

const homeState = {
  homePageUI: null,
  categoryList: null,
  additionalByLaw: null,
  error: null,
  listLoading: false,
  actionsLoading: false,
  searchLoading: false,
  isRefresh: false,
};

export const homeSlice = createSlice({
  name: "home",
  initialState: homeState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = false;
      }
    },
    throwError: (state, action) => {
      state.error = action.payload.data.error;
    },
    startCall: (state, action) => {
      state.isRefresh = false;
      state.error = null;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = true;
      }
    },
    clearError: (state) => {
      state.error = null;
    },
    homePageUIFetched: (state, action) => {
      state.listLoading = false;
      state.homePageUI = action.payload.data ?? {};
    },
    additionalByLawFetched: (state, action) => {
      state.actionsLoading = false;
      state.additionalByLaw = action.payload.data ?? {};
    },
    categoryListFetched: (state, action) => {
      state.listLoading = false;
      state.categoryList = action.payload.data ?? {};
    },
    clearHomePageUI: (state, action) => {
      state.homePageUI = null;
    },
    clearCategoryList: (state, action) => {
      state.categoryList = null;
    },
  },
});
