import { useIntl } from "react-intl";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { createContext, useEffect, useState } from "react";
import SweetAlert2 from "react-sweetalert2";
import { BottomNavConst } from "../../constants/BottomNavConst";
import { CheckIsNull } from "../../utils/CommonHelper";

const MasterLayoutContext = createContext();

export const MasterLayout = () => {
  const intl = useIntl();
  const location = useLocation();
  const path = location.pathname;
  const [_, baseUrl, subUrl] = location.pathname.split("/");

  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState({});
  const arrayHideFooter = [
    "property",
    "bankinslip",
    "statement",
    "meetingminutes",
    "bylaws",
  ];
  const subArrayHideFooter = [
    "setting",
    "faq",
    "termsandcondition",
    "privacypolicy",
    "language",
    "apphelpdesk",
  ];

  return (
    <MasterLayoutContext.Provider value={{}}>
      <SweetAlert2
        heightAuto={false}
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
        customClass="custom-swal"
      />
      <div className="d-flex flex-column h-100">
        <div className="scroll-y flex-fill w-100">
          <Outlet />
        </div>
        {!(
          arrayHideFooter.includes(baseUrl) ||
          (!CheckIsNull(subUrl) && subArrayHideFooter.includes(subUrl))
        ) && (
          <div className="align-self-end d-flex align-items-center w-100 bg-light">
            {BottomNavConst.map((bottomNav, index) => (
              <div
                key={index}
                className="d-flex flex-column align-items-center justify-content-center p-2 flex-fill cursor-pointer"
                onClick={() => {
                  navigate(`/${bottomNav.url}`);
                }}
              >
                <div>
                  <i
                    className={`${
                      path === `/${bottomNav.url}`
                        ? bottomNav.iconActive
                        : bottomNav.icon
                    } fs-5`}
                    style={{
                      color: path === `/${bottomNav.url}` ? "#5c95f3" : "#000",
                    }}
                  />
                </div>
                <div style={{ fontSize: "0.8rem" }}>
                  {intl.formatMessage({ id: bottomNav.title })}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </MasterLayoutContext.Provider>
  );
};
