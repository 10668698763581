import { useIntl } from "react-intl";
import * as actions from "../redux/BankInSlipAction";
import { useDispatch, useSelector } from "react-redux";
import { shallowEqual } from "react-intl/src/utils";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SweetAlert2 from "react-sweetalert2";
import { TabTypeEnum } from "../enum/TabTypeEnum";
import { getUniqueKey } from "../../auth/redux/LoginAction";

export const BankInSlipListing = () => {
  const location = useLocation();
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tab, setTab] = useState(
    location.state && location.state.selectedTab
      ? parseInt(location.state.selectedTab)
      : TabTypeEnum.StatusTab
  );
  const [swalProps, setSwalProps] = useState([]);

  const bankInSlipState = useSelector(
    ({ bankInSlip }) => bankInSlip,
    shallowEqual
  );

  useEffect(() => {
    if (localStorage.getItem("projectData")) {
      const projectData = JSON.parse(localStorage.getItem("projectData"));
      const queryParams = {
        url: projectData.serverurl,
        cssuserid: projectData.cssuserid,
        cssuniquekey: getUniqueKey(),
      };

      dispatch(actions.getBankInSlip(queryParams));
      return () => dispatch(actions.clearBankInSlip());
    } else {
      navigate("/home");
    }
  }, []);

  const BankInSlipCard = ({ detail }) => (
    <div
      className="card mb-2 shadow"
      onClick={() =>
        navigate(`/bankinslip/${detail.itemid}/${detail.taskid}`, {
          state: { selectedTab: tab },
        })
      }
    >
      <div className="card-body">
        <div className="fw-bold">{detail.description1}</div>
        <div className="d-flex justify-content-between align-items-start">
          <div className="text-muted" style={{ fontSize: "0.8rem" }}>
            <div>{detail.description2}</div>
            <div>{detail.description3}</div>
          </div>
          <div
            className="rounded-pill px-2 py-1 fw-semibold"
            style={{
              color: detail.statuscolorcode,
              background: detail.statusbackgroundcolorcode,
              fontSize: "0.8rem",
            }}
          >
            {detail.statusDscp}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="h-100 w-100 d-flex flex-column align-items-stretch">
        <SweetAlert2
          heightAuto={false}
          customClass="custom-swal"
          onResolve={() => {
            setSwalProps({});
          }}
          {...swalProps}
        />
        <div className="text-center position-relative p-3 bg-light fw-semibold">
          <a
            className="cursor-pointer text-decoration-none position-absolute fw-normal fs-4"
            style={{ left: "0.85rem", top: "0.8rem" }}
            onClick={() => navigate("/home")}
          >
            <i className="bi bi-chevron-left" />
          </a>
          {intl.formatMessage({ id: "bankInSlip" })}
        </div>
        {bankInSlipState && bankInSlipState.bankInSlip && (
          <div className="mb-2 bg-light d-flex container justify-content-between align-items-center py-4">
            <div
              style={{
                color: bankInSlipState.bankInSlip.description2textcolor,
              }}
            >
              {bankInSlipState.bankInSlip.description2}
            </div>
            <button
              className="border-0 rounded ms-1 ws-nowrap p-2"
              style={{
                background:
                  bankInSlipState.bankInSlip.description1backgroundcolor,
                color: bankInSlipState.bankInSlip.description1textcolor,
              }}
              onClick={() => navigate("/bankinslip/new")}
            >
              {bankInSlipState.bankInSlip.description1}
            </button>
          </div>
        )}
        <div
          className={`flex-fill bg-light scroll-y ${
            bankInSlipState && bankInSlipState.bankInSlip
              ? ""
              : "d-flex align-items-center justify-content-center"
          }`}
        >
          {bankInSlipState && bankInSlipState.bankInSlip ? (
            <>
              <div className="d-flex border-bottom mb-2 container">
                <div
                  className={`cursor-pointer ${
                    tab === TabTypeEnum.StatusTab
                      ? "tab-active fw-semibold"
                      : "text-muted"
                  } p-2 px-4`}
                  onClick={() => setTab(TabTypeEnum.StatusTab)}
                >
                  {intl.formatMessage({ id: "status" })}
                </div>
                <div
                  className={`cursor-pointer ${
                    tab === TabTypeEnum.HistoryTab
                      ? "tab-active fw-semibold"
                      : "text-muted"
                  } p-2 px-4`}
                  onClick={() => setTab(TabTypeEnum.HistoryTab)}
                >
                  {intl.formatMessage({ id: "history" })}
                </div>
              </div>
              <div className="container">
                {tab === TabTypeEnum.StatusTab ? (
                  <>
                    {bankInSlipState.bankInSlip.inprogress &&
                    bankInSlipState.bankInSlip.inprogress.length > 0 ? (
                      bankInSlipState.bankInSlip.inprogress.map(
                        (detail, index) => (
                          <BankInSlipCard detail={detail} key={index} />
                        )
                      )
                    ) : (
                      <div className="text-muted border-bottom pb-3 pt-1 px-2">
                        {intl.formatMessage({ id: "noRecord" })}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {bankInSlipState.bankInSlip.history &&
                    bankInSlipState.bankInSlip.history.length > 0 ? (
                      bankInSlipState.bankInSlip.history.map(
                        (detail, index) => (
                          <BankInSlipCard detail={detail} key={index} />
                        )
                      )
                    ) : (
                      <div className="text-muted border-bottom pb-3 pt-1 px-2">
                        {intl.formatMessage({ id: "noRecord" })}
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <div>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
