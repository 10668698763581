import { createIntl, createIntlCache, useIntl } from "react-intl";
import { getConfig } from "./Metronici18n";
import enMessages from "./messages/en.json";

// 'intl' service singleton reference
let intl;

const allMessages = {
  en: enMessages,
};

export function IntlGlobalProvider({ children }) {
  intl = useIntl(); // Keep the 'intl' service reference
  return children;
}

// Getter function to expose the read-only 'intl' service
export function appIntl() {
  const locale = getConfig()["selectedLang"];
  const messages = allMessages[locale];

  const cache = createIntlCache();
  const intl = createIntl({ locale, messages }, cache);

  return intl;
}
