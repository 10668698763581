import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import * as requestToApi from "./BankInSlipCRUD";
import { bankInSlipSlice } from "./BankInSlipSlice";

const { actions } = bankInSlipSlice;

export const getBankInSlip = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchBankInSlip(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.bankInSlipFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchBankInSlip");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const getBankInSlipDetail = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchBankInSlipDetail(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.bankInSlipDetailFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchBankInSlipDetail");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const getBankInfoList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchBankInfoList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.bankInfoListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: fetchBankInfoList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const clearBankInSlip = () => (dispatch) => {
  dispatch(actions.clearBankInSlip());
};

export const clearBankInSlipDetail = () => (dispatch) => {
  dispatch(actions.clearBankInSlipDetail());
};

export const clearBankInfoList = () => (dispatch) => {
  dispatch(actions.clearBankInfoList());
};

export const createBankInSlip = (formData) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .submitBankInSlip(formData)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.bankInSlipSubmitted({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: submitBankInSlip");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
