/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from "react-router-dom";
import { HomeListing } from "../../modules/home/component/HomeListing";
import { createContext } from "react";

const HomeContext = createContext();

const HomeWrapper = () => {
  const value = {};

  return (
    <HomeContext.Provider value={value}>
      <Routes>
        <Route path="" element={<HomeListing />} />
      </Routes>
    </HomeContext.Provider>
  );
};

export { HomeWrapper };
