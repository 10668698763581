/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from "react-router-dom";
import { BankInSlipListing } from "../../modules/bankInSlip/component/BankInSlipListing";
import { createContext } from "react";
import { BankInSlipDetail } from "../../modules/bankInSlip/component/BankInSlipDetail";
import { BankInSlipDialog } from "../../modules/bankInSlip/component/BankInSlipDialog";

const BankInSlipContext = createContext();

const BankInSlipWrapper = () => {
  const value = {};

  return (
    <BankInSlipContext.Provider value={value}>
      <Routes>
        <Route path="" element={<BankInSlipListing />} />
        <Route path="new" element={<BankInSlipDialog />} />
        <Route path=":itemid/:taskid" element={<BankInSlipDetail />} />
      </Routes>
    </BankInSlipContext.Provider>
  );
};

export { BankInSlipWrapper };
