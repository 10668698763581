/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { PropertyListing } from "../../modules/property/component/PropertyListing";
import { PropertyDialog } from "../../modules/property/component/manageProperty/PropertyDialog";

const PropertyContext = createContext();

const PropertyWrapper = () => {
  const value = {};

  return (
    <PropertyContext.Provider value={value}>
      <Routes>
        <Route path="" element={<PropertyListing />} />
        <Route path="new" element={<PropertyDialog />} />
      </Routes>
    </PropertyContext.Provider>
  );
};

export { PropertyWrapper };
