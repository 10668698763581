import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as actions from "../redux/AccountAction";
import { shallowEqual } from "react-intl/src/utils";
import SweetAlert2 from "react-sweetalert2";
import { Field, Form, Formik } from "formik";
import { Input } from "../../common/control/InputField";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { IsAlphaNumeric } from "../../../utils/CommonHelper";
import * as Yup from "yup";
import { OverlayLoading } from "../../common/OverlayLoading";

export const AccountSetting = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState([]);

  const accountState = useSelector(({ account }) => account, shallowEqual);

  useEffect(() => {
    dispatch(actions.getUserProfile());

    return () => {
      dispatch(actions.clearUserProfile());
    };
  }, []);

  const updateUserProfile = (value) => {
    dispatch(actions.editUserProfile(value)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.statuscode) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: resp.statusheader,
          text: resp.statusdscp,
          onResolve: () => {
            setSwalProps({});
            navigate("/account");
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: resp.statusheader,
          text: resp.statusdscp,
        });
      }
    });
  };

  const validationSchema = Yup.object({
    email: Yup.string().email(
      accountState.issueCategoryList
        ? accountState.issueCategoryList.caption.description7
        : intl.formatMessage({ id: "invalidEmail" })
    ),
  });

  return (
    <>
      {accountState.actionsLoading && <OverlayLoading />}
      <div className="h-100 w-100 d-flex flex-column align-items-stretch">
        <SweetAlert2
          heightAuto={false}
          customClass="custom-swal"
          onResolve={() => {
            setSwalProps({});
          }}
          {...swalProps}
        />
        <div className="text-center position-relative p-3 bg-light fw-semibold mb-2">
          <a
            className="cursor-pointer text-decoration-none position-absolute fw-normal fs-4"
            style={{ left: "0.85rem", top: "0.8rem" }}
            onClick={() => navigate("/account")}
          >
            <i className="bi bi-chevron-left" />
          </a>
          {intl.formatMessage({ id: "profileSettings" })}
        </div>
        <div
          className={`flex-fill bg-light scroll-y container pt-2 ${
            accountState && accountState.userProfile
              ? ""
              : "d-flex align-items-center justify-content-center"
          }`}
        >
          {accountState && accountState.userProfile ? (
            <>
              <Formik
                initialValues={{
                  name: accountState.userProfile.profileName,
                  phonenumber: accountState.userProfile.phoneNumber,
                  email: accountState.userProfile.email,
                }}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                  updateUserProfile(values);
                }}
              >
                {({ handleSubmit }) => (
                  <Form className="h-100 d-flex flex-column align-items-stretch">
                    <div className="mb-2">
                      <Field
                        name="name"
                        component={Input}
                        placeholder={intl.formatMessage({ id: "name" })}
                        label={intl.formatMessage({ id: "name" })}
                        autoComplete="off"
                        type="text"
                        onKeyDown={(e) => {
                          if (!IsAlphaNumeric(e)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <div className="mb-2">
                      <Field
                        name="phonenumber"
                        component={Input}
                        placeholder={intl.formatMessage({ id: "phoneNumber" })}
                        label={intl.formatMessage({ id: "phoneNumber" })}
                        autoComplete="off"
                        type="text"
                        readOnly={true}
                      />
                    </div>
                    <div className="mb-4">
                      <Field
                        name="email"
                        component={Input}
                        placeholder={intl.formatMessage({ id: "email" })}
                        label={intl.formatMessage({ id: "email" })}
                        autoComplete="off"
                        type="email"
                      />
                    </div>
                    <div className="flex-fill d-flex flex-column align-items-end justify-content-end pb-2">
                      <button
                        className="btn btn-primary w-100 position-relative"
                        disabled={accountState.actionsLoading}
                        onClick={() => handleSubmit()}
                      >
                        {intl.formatMessage({ id: "save" })}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <div>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
