import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual } from "react-intl/src/utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as actions from "../redux/AccountAction";
import SweetAlert2 from "react-sweetalert2";
import { Field, Formik, Form } from "formik";
import { Input } from "../../common/control/InputField";
import {
  CheckIsNull,
  IsAlphaNumeric,
  ResolveArrayNull,
  toAbsoluteUrl,
} from "../../../utils/CommonHelper";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { baseUrl } from "../../common/redux/ApiClient";
import { getUniqueKey } from "../../auth/redux/LoginAction";
import * as Yup from "yup";
import { OverlayLoading } from "../../common/OverlayLoading";

export const AppHelpDesk = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState([]);
  const [issue, setIssue] = useState("");
  const [files, setFiles] = useState(null);

  const inputFileRef = useRef(null);

  const accountState = useSelector(({ account }) => account, shallowEqual);

  useEffect(() => {
    dispatch(actions.getIssueCategoryList());
  }, []);

  const handleFileChanges = (e) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      setFiles(files ? [...files, selectedFile] : [selectedFile]);
    }
  };

  const handleSubmitHelpDesk = (values) => {
    const formData = new FormData();

    if (ResolveArrayNull(files).length > 0) {
      files.forEach((file) => {
        formData.append(`Image[]`, file);
      });
    }

    const queryParams = Object.entries({
      Email: values.email,
      Name: encodeURI(values.name),
      IssueCategory: encodeURI(issue),
      Description: encodeURI(values.description),
      AppVersion: "1.1",
      cssuniquekey: getUniqueKey(),
    })
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    formData.append(
      "formPostLocation",
      `${baseUrl}cssmainapi-core/api/AppHelpDesk/SubmitAppFeedBack?${queryParams}`
    );

    dispatch(actions.createAppFeedBack(formData)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.Code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: resp.MessageHeader,
          text: resp.Text,
          onResolve: () => {
            setSwalProps({});
            navigate("/account");
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: resp.MessageHeader,
          text: resp.Text,
        });
      }
    });
  };

  const validationSchema = Yup.object({
    email: Yup.string().email(
      accountState.issueCategoryList
        ? accountState.issueCategoryList.caption.description7
        : intl.formatMessage({ id: "invalidEmail" })
    ),
  });

  return (
    <>
      {accountState.actionsLoading && <OverlayLoading />}
      <div className="h-100 w-100 d-flex flex-column align-items-stretch">
        <SweetAlert2
          heightAuto={false}
          customClass="custom-swal"
          onResolve={() => {
            setSwalProps({});
          }}
          {...swalProps}
        />
        <div className="text-center position-relative p-3 bg-light fw-semibold mb-2">
          <a
            className="cursor-pointer text-decoration-none position-absolute fw-normal fs-4"
            style={{ left: "0.85rem", top: "0.8rem" }}
            onClick={() => navigate("/account")}
          >
            <i className="bi bi-chevron-left" />
          </a>
          {intl.formatMessage({ id: "appHelpdesk" })}
        </div>
        <div
          className={`flex-fill bg-light scroll-y container pt-2 ${
            accountState && accountState.issueCategoryList
              ? ""
              : "d-flex align-items-center justify-content-center"
          }`}
        >
          {accountState && accountState.issueCategoryList ? (
            <>
              <Formik
                initialValues={{
                  email:
                    accountState.issueCategoryList.caption.description1data,
                  name: accountState.issueCategoryList.caption.description2data,
                  description: "",
                }}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                  if (!CheckIsNull(values.description) && !CheckIsNull(issue))
                    handleSubmitHelpDesk(values);
                }}
              >
                {({ handleSubmit, setFieldValue, values }) => (
                  <Form className="h-100 d-flex flex-column align-items-stretch">
                    <div className="mb-2">
                      <Field
                        name="email"
                        component={Input}
                        placeholder={
                          accountState.issueCategoryList.caption.placeholder1
                        }
                        label={
                          accountState.issueCategoryList.caption.description1
                        }
                        autoComplete="off"
                        type="email"
                        readOnly={true}
                        className={
                          CheckIsNull(
                            accountState.issueCategoryList.caption
                              .description1data
                          )
                            ? "is-invalid"
                            : "is-valid"
                        }
                      />
                    </div>
                    <div className="mb-2">
                      <Field
                        name="name"
                        component={Input}
                        placeholder={
                          accountState.issueCategoryList.caption.placeholder2
                        }
                        label={
                          accountState.issueCategoryList.caption.description2
                        }
                        autoComplete="off"
                        type="text"
                        onKeyDown={(e) => {
                          if (!IsAlphaNumeric(e)) {
                            e.preventDefault();
                          }
                        }}
                        readOnly={true}
                      />
                    </div>
                    <div className="mb-2">
                      <label className="mb-2 text-input-label">
                        {accountState.issueCategoryList.caption.description3}
                      </label>
                      <select
                        className="form-select form-control-solid"
                        value={issue}
                        style={CheckIsNull(issue) ? { color: "#d3d3d3" } : {}}
                        onChange={(e) => setIssue(e.target.value)}
                      >
                        <option value="" disabled className="d-none">
                          {accountState.issueCategoryList.caption.placeholder3}
                        </option>
                        {accountState.issueCategoryList.title.map(
                          (title, index) => (
                            <option
                              key={index}
                              value={title.title}
                              style={{ color: "#000" }}
                            >
                              {title.title}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="mb-2 text-input-label">
                        {accountState.issueCategoryList.caption.description4}
                      </label>
                      <textarea
                        className="form-control form-control-solid"
                        name="description"
                        onChange={(e) =>
                          setFieldValue("description", e.target.value)
                        }
                        placeholder={
                          accountState.issueCategoryList.caption.placeholder4
                        }
                        rows={3}
                      ></textarea>
                    </div>
                    <div className="mb-2">
                      <input
                        type="file"
                        className="d-none"
                        ref={inputFileRef}
                        accept="image/*"
                        onChange={(e) => handleFileChanges(e)}
                      />
                      <div className="mb-1 d-flex align-items-center flex-wrap">
                        {files &&
                          files.map((file, index) => (
                            <div
                              key={index}
                              style={{ width: "50px", height: "50px" }}
                              className="rounded me-2 d-flex align-items-center justify-content-center border border-primary overflow-hidden"
                            >
                              <img
                                className="w-100"
                                alt={file.name}
                                src={URL.createObjectURL(file)}
                              />
                            </div>
                          ))}
                        <button
                          className="rounded d-flex justify-content-center align-items-center text-primary border border-primary"
                          type="button"
                          style={{ width: "50px", height: "50px" }}
                          onClick={() => {
                            if (
                              inputFileRef.current &&
                              ResolveArrayNull(files).length < 5
                            ) {
                              inputFileRef.current.value = "";
                              inputFileRef.current.click();
                            }
                          }}
                        >
                          <i className="bi bi-arrow-up fs-3" />
                        </button>
                      </div>
                      <div>
                        {accountState.issueCategoryList.caption.description5}
                      </div>
                    </div>
                    <div className="text-end mb-4">
                      <img
                        src={toAbsoluteUrl("images/account/whatsapp.png")}
                        alt=""
                        className="w-100 cursor-pointer"
                        style={{ maxWidth: "50px" }}
                        onClick={() =>
                          window.open(
                            `https://wa.me/${accountState.issueCategoryList.whatsapp}`
                          )
                        }
                      />
                    </div>
                    <button
                      className="btn btn-primary w-100 position-relative"
                      disabled={
                        accountState.actionsLoading ||
                        CheckIsNull(values.description) ||
                        CheckIsNull(issue)
                      }
                      onClick={() => handleSubmit()}
                    >
                      {accountState.issueCategoryList.caption.description6}
                    </button>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <div>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
