import { authFormPost, authPost, es3Url } from "../../common/redux/ApiClient";

export function fetchBankInSlip(params) {
  return authPost(es3Url, {
    action: "cssposttest",
    url: `${params.url}/cssmainapi-core/api/BankinSlip/BankInSlipView_V3`,
    data: { ...params, url: undefined },
  });
}

export function fetchBankInSlipDetail(params) {
  return authPost(es3Url, {
    action: "cssposttest",
    url: `${params.url}/cssmainapi-core/api/BankinSlip/BankInSlipViewByID_v3`,
    data: { ...params, url: undefined },
  });
}

export function fetchBankInfoList(params) {
  return authPost(es3Url, {
    action: "cssgettest",
    url: `${params.url}/cssmainapi-core/api/BankinSlip/GetBankInfoList`,
  });
}

export function submitBankInSlip(formData) {
  return authFormPost(
    `${window.location.protocol}//csshome.intechdecisions.com/api/formPost.php`,
    formData
  );
}
