import { registerLocale } from "react-datepicker";
import { useIntl } from "react-intl";
import { Navigate, Route, Routes } from "react-router-dom";
import { getDateLocale } from "../utils/DateLocale";
import { MasterLayout } from "../modules/common/MasterLayout";
import { AccountWrapper } from "../pages/account/AccountWrapper";
import { MessageWrapper } from "../pages/message/MessageWrapper";
import { HomeWrapper } from "../pages/home/HomeWrapper";
import { PropertyWrapper } from "../pages/property/PropertyWrapper";
import { BankInSlipWrapper } from "../pages/bankInSlip/BankInSlipWrapper";
import { StatementWrapper } from "../pages/statement/StatementWrapper";
import { MeetingMinutesWrapper } from "../pages/meetingMinutes/MeetingMinutesWrapper";
import { ByLawsWrapper } from "../pages/byLaws/ByLawsWrapper";

const PrivateRoutes = () => {
  const intl = useIntl();
  let locale = intl.locale;

  registerLocale(locale, getDateLocale(locale));

  return (
    <>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path="home/*" element={<HomeWrapper />} />
          <Route path="message/*" element={<MessageWrapper />} />
          <Route path="account/*" element={<AccountWrapper />} />
          <Route path="property/*" element={<PropertyWrapper />} />
          <Route path="bankinslip/*" element={<BankInSlipWrapper />} />
          <Route path="statement/*" element={<StatementWrapper />} />
          <Route path="meetingMinutes/*" element={<MeetingMinutesWrapper />} />
          <Route path="bylaws/*" element={<ByLawsWrapper />} />
          <Route path="*" element={<Navigate to={"/home"} />} />
        </Route>
      </Routes>
    </>
  );
};

export { PrivateRoutes };
